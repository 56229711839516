<template>
    <div>
        <swiper
        :slides-per-view="3"
        :space-between="50">
            <swiper-slide v-for="(item, key) in items" :key="key">
                {{item.title}}
            </swiper-slide>
        </swiper>
    </div>  
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
export default {
    components: {
        Swiper,
        SwiperSlide
    },
    computed: {
        items: {
            get() {
                return this.$store.getters['leafList/getList'];
            }
        }
    }
}
</script>