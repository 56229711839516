<template>
  <AppHeader @sidemenu="sideMenuModel=!sideMenuModel"/>
  <SlideIn v-model="sideMenuModel"/>
  <sm-loading-wrapper v-model="routerLoading"/>
  <router-view/>
</template>

<script>
import AppHeader from './components/AppHeader.vue'
import SlideIn from './components/SideMenu/SlideIn.vue';

export default {
  data() {
    return {
      sideMenuModel: false,
    }
  },
  computed: {
    routerLoading: {
      get() {
        return this.$store.getters['router/load'];
      }
    }
  },
  components: {
    AppHeader,
    SlideIn
  },
}

</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
