<template>
    <div class="pa-5">
        <div v-show="loading" class="d-flex justify-center mt-5">
            <sm-loading :size="6" color="primary"/>
        </div>
        <div v-show="!loading">
            <div class="mt-3 mb-3 d-flex-column align-end">
                <sm-button round flat width="120px" @click="onSaveClick">
                    <span v-show="saveState==null">
                        <sm-icon class="fas fa-pen" color="white"/>
                        <span class="white--text">保存</span>                        
                    </span>
                    <span v-show="saveState!==null" class="d-flex justify-center align-center">
                        <sm-loading :size="1" color="white"/>
                        <span class="white--text">保存中…</span>
                    </span>
                </sm-button>
                <div class="text-6 text-bold success--text" v-show="lastSaved!==null && lastSaveSuccess">
                    <sm-icon class="fas fa-check-circle"/>
                    {{ new Date(lastSaved).toLocaleTimeString("ja-JP", {hour: "2-digit", minute: "2-digit"}) }} 保存しました
                </div>
                <div class="text-6 text-bold error--text" v-show="!lastSaveSuccess">
                    <sm-icon class="fa solid fa-circle-exclamation"/>
                    保存に失敗しました
                </div>
            </div>
            <div class="mb-3">
                <div class="mb-3">
                    <sm-input class="w-100 text-10 text-bold" width="100vw" height="40px" placeholder="タイトル" v-model="titleModel" :bordercolor="errorMessage.title==''?undefined:'error'"
                    :borderwidth="errorMessage.title==''?undefined:2"/>
                    <span class="text-6 text-bold error--text" v-show="errorMessage.title != ''" v-text="errorMessage.title"/>
                </div>
                <MDContainer :init="mdEditorInit" v-model="contentModel" :bordercolor="errorMessage.content==''?'outline':'error'" :borderwidth="errorMessage.content==''?undefined:2"
                backgroundcolor="base"
                @save="this.onSaveClick"/>
                <span class="text-6 text-bold error--text" v-show="errorMessage.content != ''" v-text="errorMessage.content"/>
            </div>
        </div>
    </div>
</template>

<script>
import MDContainer from './MDEditor'
export default {
    props: {
        isFormChanged: Boolean
    },
    data() {
        return {
            loading: false,
            lastSaveSuccess: true,
            lastSaved: null,
            titleModel: '',
            contentModel: '',
            ctx: null,
            mdEditorInit: {
                editor: {
                    placeholder: '記事をマークダウン形式で書いてみよう'
                }
            },
            errorMessage: {
                title: '',
                content: ''
            },
            saveState: null
        }
    },
    watch: {
        contentModel() {
            this.detectFormChanged();
        },
        titleModel() {
            this.detectFormChanged();
        }
    }, 
    components:{
        MDContainer
    },
    methods: {
        async onSaveClick() {
            if (this.saveState !== null) return;
            this.saveState = 'loading';
            if (this.titleModel == '') this.titleModel = 'タイトル未設定';

            if (this.ctx) {
                this.ctx.title = this.titleModel;
                this.ctx.content = this.contentModel
                let doc = await this.$store.dispatch('leafList/updateLeaf', this.ctx);
                if (doc == null) {
                    // エラー
                    this.lastSaveSuccess = false;
                } else {
                    this.lastSaveSuccess = true;
                    this.ctx = doc;
                }
            } else {
                // insert
                let doc = {
                    user_id: null,
                    category_id: this.$store.getters['categoryList/getSelectedId'],
                    title: this.titleModel,
                    content: this.contentModel,
                }
                doc = await this.$store.dispatch('leafList/insertLeaf', doc);
                if (doc == null) {
                    // エラー
                    this.lastSaveSuccess = false;
                } else {
                    this.lastSaveSuccess = true;
                    this.ctx = doc;
                }
            }
            this.lastSaved = Date.now();
            this.saveState = null;
            if (this.lastSaveSuccess) {
                this.detectFormChanged();
            }
        },
        detectFormChanged() {
            let content = '';
            let title = '';
            if (this.ctx) {
                content = this.ctx.content;
                title = this.ctx.title;
            }
            if (content !== this.contentModel || title !== this.titleModel) {
                this.$emit('update:isFormChanged', true);
            } else {
                this.$emit('update:isFormChanged', false);
            }
        }
    },
    async mounted() {
        this.loading = true;
        let doc = null;
        if (this.$route.params.id) {
            doc = await this.$store.dispatch('leafList/fetchOne', this.$route.params.id)
        }
        if (doc) {
            this.titleModel = doc.title;
            this.contentModel = doc.content;
            this.ctx = doc;
        } else {
            this.titleModel = '';
            this.contentModel = '';
            this.ctx = null;
        }
        this.loading = false;
    },
}
</script>