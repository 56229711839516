<template>
    <div :class="{'fixed': mq=='sp'}">
        <div class="d-flex-row justify-center container">
            <swiper
            :slides-per-view="Math.min(3, items.length)" :space-between="12" @swiper="onSwiper">
                <swiper-slide v-for="(item, key) in items" :key="key" class="pb-4 pt-4 item text-center text-bold clickable text-7"
                :class="{'selected': $route.params.id==item.id}"
                v-text="item.title"
                @click="onClick(item)" ref="swiper">
                </swiper-slide>
            </swiper>
        </div>
        <sm-divider class="mr-3 ml-3" color="outline"/>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import mq from '@/plugins/mq';
let swiper = null;
export default {
    props: {
        items: Array
    },
    mixins: [mq],
    watch: {
        '$route.params.id'() {
            this.setSlideIndex();
        }
    },
    methods: {
        onSwiper(instance) {
            swiper = instance;
        },
        onClick(item) {
            this.$router.push(`/leaf/${item.id}`);
        },
        setSlideIndex(speed) {
            let i;
            for (i in this.items) {
                if (this.items[i].id == this.$route.params.id) break;
            }
            if (i > 0) i--;
            swiper.slideTo(i, speed);
        }
    },
    components: {
        Swiper,
        SwiperSlide
    },  
    mounted() {
        this.setSlideIndex(0);
    }
}
</script>

<style lang="scss" scoped>
@import './sm/scss/Sm-style-variables.scss';
@import './sm/scss/Sm-style-mediaquery.scss';
.fixed {
    position: fixed;
    width: 100%;
    z-index: 10;
}
.clickable {
    &:hover {
        cursor: pointer;
        background-color: get-color(focusback);
    }
}
.container {
    background-color: get-color(base);
    border-radius: 8px;
    @include sp {
        width: 100vw;
    }
}
.item {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: border-box;
}
.selected {
    border-bottom :5px solid get-color(primary);
}
</style>