<template>
    <sm-dialog v-model="dialogModel">
        <sm-card class="pa-4">
            <div class="text-bold">
                テーブルを挿入
            </div>
            <div class="d-flex justify-center align-center mt-5">
                <sm-select :options="columnOptions" :placeholder="placeholder" v-model="columnModel" width="160px" height="40px"
                :color="columnErr?'error':'white'" :bordercolor="columnErr?'error':undefined" :borderwidth="columnErr?2:undefined"/>
                <div class="pl-2 pr-2">
                    ✕
                </div>
                <sm-select :options="rowOptions" :placeholder="placeholder" v-model="rowModel" width="160px" height="40px"
                :color="rowErr?'error':'white'" :bordercolor="rowErr?'error':undefined" :borderwidth="rowErr?2:undefined"/>
            </div>
            <div class="d-flex justify-end mt-5">
                <sm-button flat class="mr-5" @click="onOKClick"><span class="white--text">OK</span></sm-button>
                <sm-button flat outline color="primary" @click="onCalcelClick">キャンセル</sm-button>
            </div>
        </sm-card>
    </sm-dialog>

</template>

<script>
export default {
    props: {
        modelValue: Boolean,
    },
    data() {
        return {
            columnModel: '',
            rowModel: '',
            placeholder: '選択してください',
            columnErr: false,
            rowErr: false,
        }
    },
    computed: {
        dialogModel: {
            get() {
                return this.modelValue;
            },
            set(v) {
                this.$emit('update:modelValue', v);
            }
        },
        columnOptions: {
            get() {
                let arr = [];
                for (let i = 1; i <= 9; i++) {
                    arr.push(i + '列');
                }
                return arr;
            }
        },
        rowOptions: {
            get() {
                let arr = [];
                for (let i = 1; i <= 9; i++) {
                    arr.push(i + '行');
                }
                return arr;
            }
        },
    },
    methods: {
        onOKClick() {
            let col = this.columnModel.match(/(.+)列/);
            let row = this.rowModel.match(/(.+)行/);
            if (!col || !row) {
                if (!col) this.columnErr = true;
                if (!row) this.rowErr = true;
                return;
            }
            col = col[1];
            row = row[1];
            this.$emit('ok', {
                column: col,
                row: row
            });
        },
        onCalcelClick() {
            this.dialogModel = false;
        }
    }
}
</script>