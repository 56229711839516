<template>
  <i
    :class="{ [`${icon}`]: true, [`${color}--text`]: true }"
    :style="{ 'font-size': size }"
  />
</template>

<script>
/*
<Sm-Icon icon="fab fa-twitter" color="primary" size="16px"/>

props
  icon: fas fa twitterなど
  color:
  size: font-size基準 16pxなど
*/
export default {
  props: {
    icon: String,
    color: String,
    size: String
  }
};
</script>

<style>
@import "https://use.fontawesome.com/releases/v6.4.2/css/all.css";
</style>
