<template>
    <div>
        <sm-card flat class="w-100 pr-3 pl-3 pt-2 pb-2 container d-flex">
            <div v-for="(item, key) in buttons" :key="key">
                <div v-if="!item.icon" class="divider ml-2 mr-2">
                </div>
                <sm-hover v-show="item.show">
                    <sm-button v-if="item.icon" fab flat width="30px" height="30px" color="base" class="pl-2 pr-2" @click="item.click">
                        <sm-icon :class="item.icon" :color="item.active?'primary':'shadow'" size="20px"/>
                    </sm-button>
                    <template v-slot:hover>
                        <sm-card class="tooltip pa-3 text-7" tile>
                            {{ item.desc }}
                        </sm-card>
                    </template>
                </sm-hover>
            </div>
        </sm-card>
    </div>
</template>

<script>
import mq from "@/plugins/mq";

export default {
    props: {
        editorView: String,
        syncScroll: Boolean
    },
    computed: {
        buttons: {
            get() {
                return [
                    {
                        icon: 'fas fa-image',
                        desc: '添付する画像を選択',
                        show: true,
                        click: this.onImageClick
                    },
                    {
                        icon: 'fas fa-table',
                        desc: 'テーブルを挿入',
                        show: true,
                        click: this.onInsertTableClick
                    },
                    {
                        icon: 'fa-regular fa-square',
                        desc: '穴埋め問題を挿入',
                        show: true,
                        click: this.onInsertBlankQuestionClick
                    },
                    {
                        icon: null
                    },
                    {
                        icon: 'fa-regular fa-circle-question',
                        desc: 'マークダウンの書き方/ショートカット',
                        show: true,
                        click: this.onHowToMarkdownClick
                    },
                    {
                        icon: null
                    },
                    {
                        icon: 'fas fa-pen',
                        desc: 'エディタのみ表示',
                        show: (this.mq=='pc')||(this.mq=='sp'&&this.editorView!='editorOnly'),
                        click: this.onEditOnlyViewClick,
                        active: this.editorView=='editorOnly'
                    },
                    {
                        icon: 'fas fa-table-columns',
                        desc: 'エディタ/プレビューを分割表示',
                        show: this.mq=='pc',
                        click: this.onEditPreviewViewClick,
                        active: this.editorView=='editorAndPreview'
                    },
                    {
                        icon: 'fas fa-eye',
                        desc: 'プレビューのみ表示',
                        show: this.mq=='pc'||(this.mq=='sp'&&this.editorView!='previewOnly'),
                        click: this.onPreviewOnlyViewClick,
                        active: this.editorView=='previewOnly'
                    },
                    {
                        icon: null
                    },
                    {
                        icon: 'fas fa-scroll',
                        desc: 'スクロール同期',
                        show: this.mq=='pc',
                        click: this.onToggleSyncScrollClick,
                        active: this.syncScroll
                    },
                ]
            }
        }
    },
    mixins: [mq],
    methods: {
        onImageClick(e) {
            e.preventDefault();
            this.$emit('insertImage');
        },
        onInsertTableClick(e) {
            this.$emit('insertTable');
        },
        onInsertBlankQuestionClick(e) {
            this.$emit('insertBlankQuestion');
        },
        onHowToMarkdownClick(e) {
            this.$emit('howToMarkDown');
        },
        onEditOnlyViewClick(e) {
            this.$emit('editOnlyView');
        },
        onEditPreviewViewClick(e) {
            this.$emit('editPreviewView');
        },
        onPreviewOnlyViewClick(e) {
            this.$emit('previewOnlyView');
        },
        onToggleSyncScrollClick(e) {
            this.$emit('toggleSyncScroll');
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../sm/scss/Sm-style-variables.scss';
.container {
    border: 1px solid #cccccc;
    box-sizing: border-box;
}

.divider {
    border-left: 1px solid get-color(shadow);
    width: 100%;
    height: 100%;
}

.tooltip {
    position: fixed;
    transform: translateY(5px);
}
</style>