import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import "./components/scss/blank-question.scss";
// prism.jsによるシンタックスハイライト
import "../node_modules/prismjs/themes/prism-okaidia.css"

// カスタム
import { registerComponent } from "./components/sm";
let app = createApp(App);

// カスタムコンポーネントの登録
registerComponent(app);

// カスタムディレクティブの登録
app.directive('time', (el, binding) => {
    let t = binding.value;
    let d = new Date(t).toLocaleDateString("ja-JP", {year: "numeric",month: "2-digit",
   day: "2-digit"}).replaceAll('/', '-');
    let h = new Date(t).toLocaleTimeString("ja-JP", {hour: "2-digit", minute: "2-digit"});
   el.innerText = `${d} ${h}`;
})

// テーマの反映
store.dispatch('themeController/switchTheme', store.getters['themeController/theme']);

app.use(store).use(router).mount('#app')
