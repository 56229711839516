<template>
    <div>
        <sm-loading-wrapper v-model="loadingWrapper.visible">
            <span v-text="loadingWrapper.content"/>
        </sm-loading-wrapper>
        <div class="d-flex-row justify-space-between pa-3">
            <div class="text-bold primary--text"><sm-icon class="fas fa-list"/> カテゴリ</div>
            <div v-show="!isTrashView" class="pr-2 shadow--text cursor-pointer" @click="onShowTrashClick">
                <sm-icon icon="fas fa-trash"/>
                ごみ箱を見る
            </div>
            <div v-show="isTrashView" class="pr-2 shadow--text cursor-pointer" @click="onHideTrashClick">
                <sm-icon icon="fa-solid fa-arrow-right"/>
                カテゴリに戻る
            </div>
        </div>
        <div v-for="(item, key) in items" :key="key">
            <div class="menu-item d-flex-row align-center justify-space-between pa-3" @click="onChangeCategoryClick(item)"
            :class="{'selected': item.id==selectedId}">
                <div v-text="item.name"></div>
                <sm-loading :size="1" v-show="item.state=='loading'"/>
                <sm-button fab flat color="base" @click="onCtxMenuClick($event, item)" v-show="item.state=='done'">
                    <sm-icon class="fas fa-ellipsis-v" color="shadow"/>
                </sm-button>
            </div>
        </div>
        <div class="menu-item pa-3 shadow--text" @click="onInsertCategoryClick">
            <sm-icon class="fas fa-pen"/> 新しいカテゴリを作成
        </div>
        <ContextMenu v-show="ctxMenu.visible" :positionX="ctxMenu.positionX" :positionY="ctxMenu.positionY" type="categoryList"
        @edit="onEditCategoryTrigger" @remove="onRemoveCategoryClick" @moveup="onMoveCategoryTrigger('up')" @movedown="onMoveCategoryTrigger('down')"/>
        <CagoteyEditDialog v-model:visible="categoryEditDialog.visible" v-model:categoryName="categoryEditDialog.categoryName"
        :type="categoryEditDialog.type" :ctx="categoryEditDialog.ctx" @insertOk="onInsertCategoryTrigger" @editOk="onUpdateCategoryTrigger"/>
        <ConfirmDialog okButton cancelButton cancelButtonOutline v-model="confirmDialog.visible" :content="confirmDialog.content" @ok="onRemoveCategoryTrigger"/>
        <ConfirmDialog okButton okButtonOutline v-model="rejectRemoveDialog.visible" :content="rejectRemoveDialog.content"/>
    </div>
</template>

<script>

import CagoteyEditDialog from './CategoryEditDialog.vue';
import ContextMenu from '../LeafContextMenu.vue'
import ConfirmDialog from '../ConfirmDialog.vue';

export default {
    data() {
        return {
            loadingWrapper: {
                visible: false,
                content: 'カテゴリを削除しています'
            },
            confirmDialog: {
                visible: false,
                content: 'カテゴリの中に含まれる記事が全て削除されます。<br>復元することはできません。'
            },
            rejectRemoveDialog: {
                visible: false,
                content: '記事が残っている場合はカテゴリを削除できません。<br>記事を全て削除するか、ごみ箱に移動させてください。'
            },
            categoryEditDialog: {
                visible: false,
                type: null,
                categoryName: null
            },
            ctxMenu: {
                visible: false,
                ctx: null,
                positionX: null,
                positionY: null
            }
        }
    },
    computed: {
        items: {
            get() {
                return this.$store.getters['categoryList/getList'];
            }
        },
        selectedId: {
            get() {
                return this.$store.getters['categoryList/getSelectedId'];
            }
        },
        isTrashView: {
            get() {
                return this.$route.query.trash == 'true';
            }
        }
    },
    methods: {
        onShowTrashClick() {
            let id = this.$store.getters['categoryList/getSelectedId']
            if (!id) id = ''
            this.$router.push({path: `/${id}`, query: { trash: true }});
        },
        onHideTrashClick() {
            let id = this.$route.params.id;
            if (!id) id = ''
            this.$router.push({path: `/${id}`});
        },
        onChangeCategoryClick(item) {
            this.$router.push(`/${item.id}`);
        },
        onInsertCategoryClick() {
            this.categoryEditDialog.type = 'insert';
            this.categoryEditDialog.categoryName = '';
            this.categoryEditDialog.visible = true;
        },
        async onRemoveCategoryClick() {
            let canRemove = await this.$store.dispatch('leafList/canRemoveCategory', this.ctxMenu.ctx.id);
            if (canRemove) {
                this.confirmDialog.visible = true;
            } else {
                this.rejectRemoveDialog.visible = true;
            }
        },
        async onInsertCategoryTrigger(v) {
            let category = {
                name: v
            }
            await this.$store.dispatch('categoryList/insertCategory', category);
        },
        async onUpdateCategoryTrigger(v) {
            let doc = this.ctxMenu.ctx;
            if (doc.name == v) return;
            doc.name = v;
            await this.$store.dispatch('categoryList/updateCategory', doc);
        },
        onCtxMenuClick(e, item) {
            e.stopPropagation();
            this.ctxMenu.visible = true;
            this.ctxMenu.ctx = item;
            // クリックの外側でクリックしたときにメニューを非表示にする
            document.addEventListener('click', this.hideCtxMenu);
            this.ctxMenu.positionX = e.clientX;
            this.ctxMenu.positionY = e.clientY;
        },
        hideCtxMenu(e) {
            e.stopPropagation();
            this.ctxMenu.visible = false;
            document.removeEventListener('click', this.hidectxMenu);
        },
        onEditCategoryTrigger() {
            this.categoryEditDialog.type = 'edit';
            this.categoryEditDialog.categoryName = this.ctxMenu.ctx.name;
            this.categoryEditDialog.visible = true;
        },
        async onRemoveCategoryTrigger() {
            this.loadingWrapper.visible = true;
            await this.$store.dispatch('categoryList/removeCategory', this.ctxMenu.ctx.id);
            if (this.ctxMenu.ctx.id == this.$store.getters['categoryList/getSelectedId']) {
                await this.$store.commit('categoryList/updateSelectedId', null);
            }
            this.loadingWrapper.visible = false;
            this.$router.push('/');
        },
        onMoveCategoryTrigger(direction) {
            let i = this.getItemIndexById(this.ctxMenu.ctx.id);
            let di = this.items[i].display_index;
            let target;
            if (direction == 'up') {
                if (i == 0) return;
                this.items[i].display_index = this.items[i - 1].display_index;
                target = this.items[i - 1];
            } else if (direction == 'down') {
                if (i == this.items.length - 1) return;
                target = this.items[i + 1];
            }
            this.items[i].display_index = target.display_index;
            target.display_index = di;
            this.$store.dispatch('categoryList/updateCategory', this.items[i]);
            this.$store.dispatch('categoryList/updateCategory', target);
        },
        getItemIndexById(id) {
            let i;
            for (i in this.items) {
                if (this.items[i].id == id) break;
            }
            return Number(i);
        }
    },
    components: {
        CagoteyEditDialog,
        ContextMenu,
        ConfirmDialog
    }
}
</script>

<style lang="scss" scoped>
@import "@/components/sm/scss/Sm-style-variables.scss";
.menu-item {
    cursor: pointer;
    box-sizing: border-box;
    &:hover {
        background-color: get-color(focusback);
    }
}
.selected {
    border-left: 5px solid get-color(primary);
}
</style>