<template>
  <div>
    <column-layout>
      <sm-card class="pt-3 pb-3">
        <leaf-list/>
      </sm-card>
    </column-layout>
  </div>
</template>

<script>
import LeafList from '../components/LeafList.vue';
import ColumnLayout from '../components/ColumnLayout.vue';
export default {
  components: {
    LeafList,
    ColumnLayout
  }
}
</script>

<style lang="scss" scoped>
</style>