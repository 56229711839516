<template>
    <div class="d-flex justify-center">
        <div class="wrapper d-flex-column justify-center align-center">
            <div class="d-flex-column justify-center align-center">
                <img src="@/assets/service_logo.png" :class="{'w-40': mq=='pc', 'w-100': mq=='sp'}"/>
                <div class="text-bold shadow--text text-align-center" :class="{'text-8': mq=='pc', 'text-6': mq=='sp'}">
                    いつでも、なんでも記録できるプライベートなルーズリーフアプリ
                </div>
            </div>
            <div class="pa-5 d-flex-column justify-center align-center" :class="{'w-30': mq=='pc', 'w-80': mq=='sp'}">
                <form class="w-100">
                    <sm-input v-model="mailModel" type="email" class="pb-3 w-100 text-7" width="100%" height="30px" placeholder="メールアドレス"></sm-input>
                    <sm-input v-model="pwModel" type="password" class="pb-5 w-100 text-7" width="100%" height="30px" placeholder="パスワード"/>
                    <sm-button class="mb-5" round @click="onSigninClick" width="100%" height="30px">
                        <div class="pr-2">
                            <sm-icon v-if="!signinLoading" class="fa-solid fa-right-to-bracket"/>
                            <sm-loading v-if="signinLoading" :size="1" color="base"/>
                        </div>
                        ログイン
                    </sm-button>
                    <div v-show="signinerror" class="text-center error--text text-6 text-bold">
                        メールアドレスかパスワードが正しくありません
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import mq from '@/plugins/mq';
export default {
    data() {
        return {
            mailModel: '',
            pwModel: '',
            signinLoading: false,
            signinerror: false
        }
    },
    mixins: [mq],
    methods: {
        async onSigninClick() {
            if (this.signinLoading) return;
            this.signinLoading = true;
            let res = await this.$store.dispatch('auth/signin', {
                email: this.mailModel,
                password: this.pwModel
            });
            if (res) {
                this.$router.push('/');
            } else {
                this.signinLoading = false;
                this.signinerror = true;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/components/sm/scss/Sm-style-mediaquery.scss";
.wrapper {
    height: 80vh;
    @include pc {
        width: 80vw;
    }
    @include sp {
        width: 90vw;
    }
}
</style>