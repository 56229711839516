<template>
  <div :class="{ divider: true, [`${color}--text`]: true }" />
</template>

<script>
/*
props
  color: 線の色
*/
export default {
  props: {
    color: String
  }
};
</script>

<style lang="scss" scoped>
.divider {
  border-bottom: 1px solid;
}
</style>
