<template>
    <sm-header sticky height="60px" class="index-top app-header--bg">
        <div v-if="mq=='sp'" class="d-flex h-100 justify-space-between align-center pr-4">
            <sm-button fab flat height="40px" width="40px" color="app-header" v-if="mq=='sp'" @click="$emit('sidemenu')">
                <sm-icon size="20px" class="fas fa-bars" color="white"/>
            </sm-button>
            <div class="text-bold white--text text-12 cursor-pointer" @click="$router.push('/')">
                <sm-icon class="fas fa-leaf" color="white"/>
                <span class="white--text text-bold">Leaf</span>
            </div>
        </div>
        <div v-if="mq=='pc'" class="d-flex h-100 justify-space-between align-center width-for-pc">
            <div class="d-flex align-center">
                <div class="text-bold white--text text-12 cursor-pointer" @click="$router.push('/')">
                    <sm-icon class="fas fa-leaf" color="white"/>
                    <span class="white--text text-bold">Leaf</span>
                </div>
                <div class="ml-10">
                    <form @submit.prevent="onSearchSubmit">
                        <sm-input v-model="searchInputModel" class="text-7" height="24px" width="240px" nooutline backgroundcolor="back" placeholder="記事を検索"/>
                    </form>
                </div>
            </div>
            <div class="d-flex justify-space-between align-center">
                <div>
                    <theme-switch/>
                </div>
                <div class="ml-10">
                    <sm-button v-if="isAuthorized" fab flat height="40px" width="40px" @click="onSignOutClick">
                        <sm-icon size="20px" class="fas fa-right-from-bracket" color="white"/>
                    </sm-button>
                    <div v-if="!isAuthorized" class="base--text text-6 text-bold">
                        ログインしていません
                    </div>
                </div>
            </div>
        </div>
    </sm-header>
</template>

<script>
import mq from "@/plugins/mq";
import ThemeSwitch from "./ThemeSwitch.vue";
export default {
    mixins: [mq],
    data() {
        return {
            searchInputModel: '',
        }
    },
    computed: {
        isAuthorized: {
            get() {
                return !!this.$store.getters['auth/user'];
            }
        }
    },
    methods: {
        async onSignOutClick() {
            await this.$store.dispatch('auth/signout');
            this.$router.push('/signin')
        },
        onSearchSubmit() {
            if (this.searchInputModel) {
                let path = '/' + this.$store.getters['categoryList/getSelectedId'];
                let q = {search: this.searchInputModel}
                if (this.$route.query.trash) q.trash = this.$route.query.trash;
                this.$router.push({path: path, query:q});
            }
        },
        onThemeSwitch(v) {
            console.log(v)
        }
    },
    components: {
        ThemeSwitch
    }
}
</script>

<style lang="scss" scoped>
@import "@/components/scss/column-settings.scss";
.index-top {
    z-index: 100;
}
.width-for-pc {
    max-width: $wrapper-width;
    margin-right: auto;
    margin-left: auto;
}
</style>