<template>
  <div
    :class="{
      footer: true,
      fixed: fixed,
      flat: flat,
      outline: outline,
      [`${color}--text`]: true,
      [`${backgroundcolor}--bg`]: true
    }"
    :style="{ height: height }"
  >
    <slot />
  </div>
</template>

<script>
/*
margin: 0pxを設定すること

props
  fixed: 固定するかどうか
  height:
  color: 文字色
  backgroundcolor: 背景色
  flat: 陰影
  outline: 下線
*/
export default {
  props: {
    fixed: Boolean,
    flat: Boolean,
    outline: Boolean,
    height: String,
    backgroundcolor: String,
    color: String
  }
};
</script>

<style lang="scss" scoped>
@import "./scss/Sm-style-variables.scss";
.footer {
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 0px 6px -1px get-color(shadow);
}
.fixed {
  position: fixed;
}
.flat {
  box-shadow: none;
}
.outline {
  box-shadow: none;
  border-top: 1px solid get-color(outline);
}
</style>
