<template>
    <sm-switch v-model="model" @click="onClick" color="back">
        <template v-slot:button-overlay>
            <sm-icon :class="getIcon" color="default"/>
        </template>
    </sm-switch>
</template>

<script>
export default {
    data() {
        return {
            model: true
        }
    },
    computed: {
        getIcon() {
            return this.model ? 'fas fa-sun' : 'fas fa-moon'
        }
    },
    methods: {
        onClick() {
            let theme = this.model ? 'light' : 'dark';
            this.$store.dispatch('themeController/switchTheme', theme);
        }
    },
    created() {
        if (this.$store.getters['themeController/theme'] == 'light') {
            this.model = true;
        } else {
            this.model = false;
        }
    }
}

</script>