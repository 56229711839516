<template>
  <div
    :class="{
      'd-flex-row': flex === 'row',
      'd-flex-column': flex === 'column',
      'justify-space-between': true
    }"
  >
    <div
      v-for="(item, i) in items"
      :key="i"
      @click="onClick(i)"
      class="w-100 h-100"
    >
      <slot :item="item" :index="i" :active="value === i" />
    </div>
  </div>
</template>

<script>
/*
<sm-tab :items="[apple, orange, banana]", flex="row">
  <template v-slot:default="tabProps">
    <div :class="{'primary--bg': tabProps.active}">
      {{tabProps.item}} //テキスト表示
    </div>
  </template>
</sm-tab>

v-model(number): アクティブなインデックス（クリックで更新）
slots
  default: v-forの中で使用 いくつかプロパティを受け取れる
    item: itemsに指定したオブジェクト
    index: arrayのindex
    active: 選択状態かどうか
props
  items(array): v-forで受け渡される中身（文字列でもオブジェクトでもOK）
  flex(str): row / column タブの方向
*/
export default {
  props: {
    items: Array,
    value: Number,
    flex: String
  },
  methods: {
    onClick(index) {
      this.$emit("input", index);
    }
  }
};
</script>
