<template>
  <div class="d-flex-row">
    <textarea
      :placeholder="placeholder"
      :type="type"
      :value="modelValue"
      :readonly="readonly"
      :rows="rows"
      @input="onInput($event)"
      :class="{ 'no-outline': nooutline,
      [`${backgroundcolor}--bg`]: backgroundcolor==undefined ? false : true,
      [`${bordercolor}--border`]: bordercolor==undefined ? false : true,
      }"
      :style="{ width: width, fontSize: 'inherit', fontWeight: 'inherit', color: 'inherit',
      borderWidth: `${borderwidth? borderwidth : 1}px`}"
      ref="textarea"
    />
    <div class="text-9 text-bold">
      <slot name="append" />
    </div>
  </div>
</template>

<script>
/*
props
  placeholder(str): プレースホルダ
  type(bool): タイプ
  readonly:
  width(str):
  rows(str): 行数
  autoresize(bool): 自動拡縮
  nooutline(bool): ボーダー
  backgroundcolor(str[color]): 背景色
*/
export default {
  props: {
    modelValue: String,
    placeholder: String,
    type: String,
    readonly: Boolean,
    width: String,
    rows: String,
    autoresize: Boolean,
    nooutline: Boolean,
    backgroundcolor: String,
    bordercolor: String,
    borderwidth: Number
  },
  methods: {
    onInput(e) {
      if (this.autoResize) {
        this.autoResize(e.target);
      }
      this.$emit("update:modelValue", e.target.value);
    },
    autoResize(target) {
      target.style.height = "auto";
      target.style.height = target.scrollHeight + "px";
    }
  },
  mounted() {
    this.autoResize(this.$refs.textarea);
  }
};
</script>

<style lang="scss" scoped>
.no-outline {
  border: none;
  &:focus {
    outline: none;
  }
}
</style>
