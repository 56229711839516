<template>
    <div class="container" :style="{top: `${positionY}px`, left:`${positionX -width - 20}px`}">
        <sm-card tile class="pt-3 pb-3">
            <div v-for="(item, key) in items" :key="key" v-show="item.show" class="pt-2 pb-2 pr-4 pl-4 menu-item"
            :class="item.color + '--text'" @click="handler($event, item.handler)">
                <sm-icon :class="item.icon"/>
                {{ item.title }}
            </div>
        </sm-card>
    </div>
</template>

<script>
export default {
    props: {
        ctxId: String,
        type: String,
        positionX: Number,
        positionY: Number
    },
    data() {
        return {
            items_leafList: [
                {
                    title: '記事を編集',
                    icon: 'fas fa-pen',
                    handler: 'edit',
                    show: true
                },
                {
                    title: '下に記事を作成',
                    icon: 'fas fa-circle-plus',
                    handler: 'appendLeaf',
                    show: true
                },
                {
                    title: 'ひとつ上に移動',
                    icon: 'fas fa-arrow-up',
                    handler: 'moveup',
                    show: true
                },
                {
                    title: 'ひとつ下に移動',
                    icon: 'fas fa-arrow-down',
                    handler: 'movedown',
                    show: true
                },
                {
                    title: '記事をごみ箱に移動',
                    icon: 'fas fa-trash',
                    color: 'error',
                    handler: 'moveToTrash',
                    show: true
                },
                {
                    title: '元に戻す',
                    icon: 'fa-solid fa-arrow-right',
                    handler: 'restoreFromTrash',
                    show: true
                },
                {
                    title: '記事を削除',
                    icon: 'fas fa-trash',
                    color: 'error',
                    handler: 'remove',
                    show: true
                }
            ],
            items_leafItem: [
                {
                    title: '記事を編集',
                    icon: 'fas fa-pen',
                    handler: 'edit',
                    show: true
                },
                {
                    title: '記事を削除',
                    icon: 'fas fa-trash',
                    color: 'error',
                    handler: 'remove',
                    show: true
                }
            ],
            items_categoryList: [
                {
                    title: '名前を編集',
                    icon: 'fas fa-pen',
                    handler: 'edit',
                    show: true
                },
                {
                    title: 'ひとつ上に移動',
                    icon: 'fas fa-arrow-up',
                    handler: 'moveup',
                    show: true
                },
                {
                    title: 'ひとつ下に移動',
                    icon: 'fas fa-arrow-down',
                    handler: 'movedown',
                    show: true
                },
                {
                    title: 'カテゴリを削除',
                    icon: 'fas fa-trash',
                    color: 'error',
                    handler: 'remove',
                    show: true
                }
            ],
            width: 200
        }
    },
    computed: {
        items: {
            get() {
                if (this.type == 'leafList') {
                    return this.hideItemByHandler(this.items_leafList, ['restoreFromTrash', 'remove']);
                } else if (this.type == 'leafListInTrash') {
                    return this.hideItemByHandler(this.items_leafList, 'moveToTrash');
                } else if (this.type == 'leafItem') {
                    return this.items_leafItem;
                } else if (this.type == 'categoryList') {
                    return this.items_categoryList;
                }
                return [];
            }
        }
    },
    methods: {
        hideItemByHandler(items, handlers) {
            if (typeof handlers == 'string') handlers = [handlers];
            for (let item of items) {
                item.show = true;
                for (let handler of handlers) {
                    if (item.handler == handler) {
                        item.show = false;
                    }
                }
            }
            return items;
        },
        handler(e, handler) {
            this.$emit(handler, this.ctxId, e);
        }
    }
}
</script>

<style lang="scss" scoped>
@import './sm/scss/Sm-style-variables.scss';
.container {
    position: fixed;
    width: 200px;
}
.menu-item {
    cursor: pointer;
    &:hover {
        background-color: get-color(focusback);
    }
}
</style>