<template>
    <sm-dialog v-model="dialogModel" :maxheight="mq=='sp'?'90vh':'80vh'">
        <sm-card tile class="wrapper">
            <div class="pa-3">
                <div class="text-bold text-12">
                    マークダウンの書き方
                </div>
                <div v-html="context"/>
            </div>
            <div class="footer w-100" v-if="mq=='sp'">
                <sm-card tile class="text-center" @click="this.dialogModel=false">
                    <sm-divider color="outline"/>
                    <div class="pa-5 text-bold">
                        <sm-icon class="fa-solid fa-xmark"/>
                        閉じる
                    </div>
                </sm-card>
            </div>
        </sm-card>
    </sm-dialog>

</template>

<script>
import text from './HowToMarkDownDialog.js';
import mq from "@/plugins/mq";
import toHtml from '@/plugins/marked';

export default {
    props: {
        modelValue: Boolean,
    },
    computed: {
        dialogModel: {
            get() {
                return this.modelValue;
            },
            set(v) {
                this.$emit('update:modelValue', v);
            }
        },
        context: {
            get() {
                return toHtml(text);
            }
        }
    },
    mixins: [mq],
    methods: {
        onOKClick() {
            let col = this.columnModel.match(/(.+)列/);
            let row = this.rowModel.match(/(.+)行/);
            if (!col || !row) {
                if (!col) this.columnErr = true;
                if (!row) this.rowErr = true;
                return;
            }
            col = col[1];
            row = row[1];
            this.$emit('ok', {
                column: col,
                row: row
            });
        },
        onCalcelClick() {
            this.dialogModel = false;
        }
    }
}
</script>

<style lang="scss" scoped>
@import '../sm/scss/Sm-style-mediaquery.scss';
;
.wrapper {
    width: 80vw;
    @include sp {
        width: 100vw;
    }
}
.footer {
    position: sticky;
    bottom: 0;
    left: 0;
    width: 100%;
}
</style>