<template>
  <div @click="onClick">
    <div class="dammy">
      <div
        :class="{
          base: true,
          pointer: !disabled,
          'base-disabled': !modelValue,
          'base-enabled': modelValue,
          'disabled--bg': !modelValue,
          [`${color}--bg`]: modelValue
        }"
      ></div>
      <div
        :class="{
          button: true,
          pointer: !disabled,
          'button-disabled': !modelValue,
          'button-enabled': modelValue,
          'base--bg': !modelValue,
          [`${color}--bg`]: modelValue,
          'center-all': true,
        }"
      >
        <slot name="button-overlay"/>
      </div>
    </div>
  </div>
</template>

<script>
/*
<sm-switch v-model="switchModel" color="primary"/>

v-model: enable=>true, disable=>falseを指定
props
  disabled(bool): 操作可能かどうか
  color(str[color]): trueのときの色
*/
import cssVar from "./scss/Sm-style-variables.scss";
import { colorCodeToRgba } from "./js/rgba.js";

export default {
  props: {
    modelValue: Boolean,
    disabled: Boolean,
    color: String
  },
  data() {
    return {
      buttonHover: false
    };
  },
  methods: {
    onClick() {
      if (this.disabled) return;
      this.$emit("update:modelValue", !this.modelValue);
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./scss/Sm-style-variables.scss";
.dammy {
  position: relative;
  width: 48px;
}
.base {
  height: 24px;
  width: 48px;
  border-radius: 20px;
  transition: all 0.3s ease;
  &-disabled {
  }
  &-enabled {
    opacity: 0.4;
  }
}
.button {
  position: absolute;
  top: 0px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  box-shadow: 0px 2px 6px 0px get-color(shadow);
  transition: all 0.3s ease;
  left: 28px;
  &-disabled {
    left: 0;
  }
  &-enabled {
  }
}
.pointer {
  cursor: pointer;
}
</style>
