<template>
    <div class="wrapper pr-3 pl-3 pa-3" ref="ctx" v-show="show">
        <div v-html="contextHtml" ref="previewRoot"/>
    </div>
</template>

<script>
import markdown from '@/plugins/marked';

export default {
    props: {
        show: Boolean,
        context: String,
        syncScroll: Boolean,
        cursorLines: Number
    },
    data() {
        return {
            scrollMap: null,
            contextHtml: '',
            md: null
        }
    },
    watch: {
        cursorLines(v) {
            if (!this.show) return;
            if (!this.syncScroll) return;
            this.scrollMap = this.md.toScrollMap(this.$refs.previewRoot);
            if (this.scrollMap.length == 0) return;
            let currentLine = this.cursorLines;
            let i;
            for (i in this.scrollMap) {
                if (Number(this.scrollMap[i][0]) >= this.cursorLines) {
                    break;
                }
            }
            let ai = Math.max(0, i);
            let bi;
            let j = 1;
            while(i - j > 0) {
                if (this.scrollMap[i - j][0] > 0)break;
                j++;
            }
            bi = i - j;
            bi = Math.max(0, bi);

            let afterLine = this.scrollMap[ai][0];
            let beforeLine = this.scrollMap[bi][0];
            let afterScrollY = this.scrollMap[ai][1];
            let beforeScrollY = this.scrollMap[bi][1];

            // beforeとafterに対する比率を算出
            let distanceFromBeforeLine = (currentLine - beforeLine) / (afterLine - beforeLine);
            // 比率に対するスクロール位置を算出
            let scrollY = (afterScrollY - beforeScrollY) * distanceFromBeforeLine + beforeScrollY;
            // パディング
            scrollY -= 200;
            this.$refs.ctx.scrollTop = scrollY;
        },
        context(v) {
            this.updateContextHtml();
        }
    },
    methods: {
        async updateContextHtml() {
            this.md = await markdown(this.context);
            this.contextHtml = await this.md.toHtml();            
            /*
            setTimeout(() => {
                this.md = markdown(this.context);
                this.contextHtml = this.md.toHtml();            
            }, 10);
            */
        }
    },
    mounted() {
        this.updateContextHtml();
    }
}
</script>

<style lang="scss" scoped>
@import '../sm/scss/Sm-style-variables.scss';
.wrapper {
    scroll-behavior: smooth;
    width: 100%;
    height: 520px;
    overflow-y: auto;
    background-color: get-color(base);
    border: 1px solid get-color(outline);
    border-collapse: collapse;
    border-radius: 0px 8px 8px 0px;
    box-sizing: border-box;
}
</style>