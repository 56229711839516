<template>
  <div
    :class="{
      header: true,
      sticky: sticky,
      [`${color}--text`]: true,
      [`${backgroundcolor}--bg`]: true,
      flat: flat,
      outline: outline
    }"
    :style="{ height: height }"
  >
    <slot />
  </div>
</template>

<script>
/*
<sm-header fixed height="40px" color="default" backgroundcolor="primary", flat, outline>
  hoge
</sm-header>

margin: 0pxをbodyに設定すること

props
  sticky: 固定するかどうか
  height:
  color: 文字色
  backgroundcolor: 背景色
  flat: 陰影
  outline: 下線
*/
export default {
  props: {
    sticky: Boolean,
    flat: Boolean,
    outline: Boolean,
    height: String,
    backgroundcolor: String,
    color: String
  }
};
</script>

<style lang="scss" scoped>
@import "./scss/Sm-style-variables.scss";
.header {
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 2px 6px -1px get-color(baseshadow);
}
.sticky {
  position: sticky;
}
.flat {
  box-shadow: none;
}
.outline {
  box-shadow: none;
  border-bottom: 1px solid get-color(outline);
}
</style>
