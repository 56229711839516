<template>
    <div>
        <transition name="fade">
            <div class="overlay" v-if="isMenuOpen" @click="isMenuOpen=false"></div>
        </transition>
        <div class="menu" :class="{'slide-in': modelValue, 'slide-out': !modelValue}">
            <sm-card tile class="menu-base pl-2">
                <div class="d-flex justify-end pr-8 pt-5">
                    <ThemeSwitch/>
                </div>
                <div class="pa-1 pb-5 pt-5">
                    <form @submit.prevent="onSearchSubmit">
                        <sm-input v-model="searchInputModel" class="text-7" height="24px" width="90%" backgroundcolor="back" placeholder="記事を検索"/>
                    </form>            
                </div>
                <MenuList v-if="isAuthorized"/>
                <div v-if="isAuthorized" @click="onSignOutClick" class="error--text">
                    <sm-divider color="outline" class="ma-3"/>
                    <div class="pl-2 mt-4">
                        <sm-icon size="20px" class="fas fa-right-from-bracket"/>
                        ログアウト
                    </div>
                </div>
                <div v-if="!isAuthorized" class="pa-3">
                    ログインしていません
                </div>
            </sm-card>
        </div>
    </div>
</template>

<script>
import ThemeSwitch from '../ThemeSwitch.vue';
import MenuList from './MenuList.vue';

export default {
    props: {
        modelValue: Boolean
    },
    data() {
        return {
            searchInputModel: '',
        }
    },
    computed: {
        isAuthorized: {
            get() {
                return !!this.$store.getters['auth/user'];
            }
        },
        isMenuOpen: {
            get() {
                return this.modelValue;
            },
            set(v) {
                this.$emit('update:modelValue', v);
            }
        }
    },
    components: { 
        MenuList,
        ThemeSwitch
    },
    methods: {
        onSearchSubmit() {
            if (this.searchInputModel) {
                let path = '/' + this.$store.getters['categoryList/getSelectedId'];
                let q = {search: this.searchInputModel}
                if (this.$route.query.trash) q.trash = this.$route.query.trash;
                this.$router.push({path: path, query:q});
            }
        },
        async onSignOutClick() {
            await this.$store.dispatch('auth/signout');
            this.$router.push('/signin')
        }
    }
}
</script>

<style lang="scss" scoped>
@import "@/components/scss/column-settings.scss";
.overlay {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 98;
    opacity: 0.6;
    background-color: black;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s ease;
}

.fade-enter-from, .fade-leave-to {
    opacity: 0;
}
.menu {
    position: fixed;
    top: $app-header-height;
    left: -100%; /* メニューを画面外に配置 */
    width: 80%; /* メニューの幅 */
    max-width: 400px;
    height: 100%;
    z-index: 99;
}

/* メニューが表示されるときのスタイル */
.menu.slide-in {
    left: 0; /* メニューを画面内にスライド */
    transition: left .5s ease; /* スライドアニメーションの設定 */
}

/* メニューが閉じられたときのスタイル */
.menu.slide-out {
    left: -100%; /* メニューを画面外にスライド */
    transition: left .5s ease; /* スライドアウトアニメーションの設定 */
}

.menu-base {
    height: 100%;
}
</style>