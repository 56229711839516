<template>
    <div class="overlay-wrapper">
        <div class="overlay" @click.prevent="exit">
            <div class="white--text pa-5">
                <sm-icon class="fas fa-xmark cursor-pointer" size="24px" @click="exit"/>
            </div>
        </div>
        <div class="dialog-wrapper">
            <div class="dialog">
                <img :src="src"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            src: this.$store.getters['zoomImage/src']
        }
    },
    methods: {
        exit() {
            this.resetScale();
            this.$store.commit('zoomImage/src', '');
        },
        resetScale() {
            let metalist = document.getElementsByTagName('meta');
            let target = 0;
            for(let i = 0; i < metalist.length; i++) {
                let name = metalist[i].getAttribute('name');
                if(name && name.toLowerCase() === 'viewport') {
                    metalist[i].setAttribute('content', 'width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no');
                    target = i;
                    break;
                }
            }
            // $nextTickだとピンチインがリセットされる前に発火してしまうので、ダサいがsetTimeoutで元に戻す
            setTimeout(() => {
                metalist[target].setAttribute('content', 'width=device-width,initial-scale=1.0');
            }, 100);
        }
    }
}
</script>

<style lang="scss" scoped>
.overlay-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9998;
}
.overlay {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #000000;
  opacity: 0.9;
}
.dialog-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 9999;
}
.dialog {
  overflow-x: hidden;
  overflow-y: auto;
}

</style>