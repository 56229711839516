// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, where, orderBy, limit, select, query, doc, getDocs, getDoc, setDoc, addDoc, deleteDoc } from 'firebase/firestore/lite';
import { getStorage, ref, uploadBytes, getDownloadURL, listAll, deleteObject } from "firebase/storage";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signInWithCustomToken, signOut } from 'firebase/auth';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCam8QRyS1oKKin3fJI6gUkwUSp02_iFzk",
  authDomain: "leaf-ef4dd.firebaseapp.com",
  projectId: "leaf-ef4dd",
  storageBucket: "leaf-ef4dd.appspot.com",
  messagingSenderId: "688360633973",
  appId: "1:688360633973:web:0f8e4a3327146d54f7ea3c",
  measurementId: "G-9VJGQS7KE2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);

const firestore = {
  db: getFirestore(app),
  collection,
  query,
  where,
  orderBy,
  limit,
  select,
  doc,
  getDocs,
  getDoc,
  setDoc,
  addDoc,
  deleteDoc,
  auth: getAuth(),
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut,
  storage,
  ref,
  uploadBytes,
  getDownloadURL,
  listAll,
  deleteObject
}

export default firestore