const mq = {
    data() {
        return {
            mq: 'pc'
        }
    },
    methods: {
        __handleResize() {
            let w = window.innerWidth;
            if (w < 980) {
                this.mq = 'sp';
            } else {
                this.mq = 'pc';
            }                
        }
    },
    mounted() {
        this.__handleResize();
        window.addEventListener('resize', this.__handleResize);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.__handleResize);
    }
}

export default mq;