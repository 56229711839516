<template>
    <div>
        <column-layout>
            <template #default>
                <zoom-image v-if="$store.getters['zoomImage/src']!==''"/>
                <sm-card v-if="!ctx && !loading" class="pa-10 text-center text-9">
                    <sm-icon class="fas fa-leaf"/>
                    指定された記事は見つかりませんでした
                </sm-card>
                <sm-card class="pb-4 wrapper" v-if="ctx || loading">
                    <leaf-header :items="items"/>
                    <leaf-item :ctx="ctx" :ctxHtml="ctxHtml" class="leaf-item" @toLeft="onSwipe('left')" @toRight="onSwipe('right')"/>
                </sm-card>
            </template>

            <template #menu-top>
                <sm-card class="pt-2 pb-2 mb-2 leaf-item-index-wrapper scroll-box">
                    <leaf-item-index :ctx="ctxToc"/>
                </sm-card>
            </template>
        </column-layout>
    </div>
  </template>
  
<script>
import markdown from '@/plugins/marked';
import ColumnLayout from '../components/ColumnLayout.vue';
import LeafHeader from '../components/LeafHeader.vue';
import LeafItem from '../components/LeafItem.vue';
import LeafItemIndex from '../components/SideMenu/LeafItemIndex.vue';
import ZoomImage from '../components/ZoomImage.vue';
export default {
    data() {
        return {
            loading: false,
            ctx: null,
            ctxHtml: null,
            ctxToc: null,
            zoomImageSrc: this.$store.getters['zoomImage/src']
        }
    },
    components: {
        ColumnLayout,
        LeafHeader,
        LeafItem,
        LeafItemIndex,
        ZoomImage
    },
    watch: {
        $route(to, from) {
            if (to.path !== from.path) {
                this.updateCtx();
            }
        }
    },
    computed: {
        items: {
            get() {
                return this.$store.getters['leafList/getList'];
            }
        }
    },
    methods: {
        async updateCtx() {
            this.loading = true;
            this.ctx = null;
            let doc = await this.$store.dispatch('leafList/fetchOne', this.$route.params.id);
            if (!doc) {
                this.loading = false;
                return;
            }
            let md = await markdown(doc.content);
            this.ctx = doc;
            this.ctxHtml = await md.toHtml();
            this.ctxToc = await md.toToc() || '<ul>目次がありません</ul>';
            this.loading = false;
        },
        onSwipe(direction) {
            let list = this.items;
            let i;
            for (i in list) {
                if (list[i].id == this.$route.params.id) {
                    break;
                }
            }
            if (direction == 'right') {
                i = Number(i) + 1;
                if (i >= list.length) return;
            } else if (direction == 'left') {
                i = Number(i) - 1;
                if (i < 0) return;
            }
            this.$router.push(`/leaf/${list[i].id}`);
        },
    },
    mounted() {
        this.updateCtx();
    }
}
</script>

<style lang="scss" scoped>
@import "@/components/scss/column-settings.scss";
@import "@/components/scss/scrollbar.scss";
@import "@/components/sm/scss/Sm-style-mediaquery.scss";
.leaf-item {
    @include sp {
        padding-top: 50px;
    }
}
.overlay {
    z-index: 1000;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.3;
}
.leaf-item-index-wrapper {
    max-height: 40%;
    overflow-y: scroll;
}
</style>