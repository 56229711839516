<template>
    <div>
        <div v-if="leafListLoading==true" class="d-flex justify-center">
            <sm-loading :size="6" color="primary"/>
        </div>
        <!--Default View-->
        <div v-if="!leafListLoading && leafList.length == 0 && !isSearchView && !isTrashView" class="pa-4 text-center">
            <div v-if="$store.getters['categoryList/getSelectedId']!==null">
                <div class="text-10 text-bold pa-4">このカテゴリにはまだ記事がありません</div>
                <div class="text-8 primary--text cursor-pointer" @click="onEditButtonClick"><sm-icon class="fas fa-pen"/>最初の記事を作成する</div>
            </div>
            <div v-else class="shadow--text">
                <div class="text-15 text-bold primary--text mb-4"><sm-icon class="fas fa-leaf"/>Leafへようこそ</div>
                <div class="text-8" v-text="'まずはカテゴリを作成しましょう'"/>
            </div>
        </div>
        <!--Search View-->
        <div class="ml-5 mb-5" v-if="isSearchView">
            <div class="shadow--text d-flex align-center">
                <sm-icon class="fa-solid fa-magnifying-glass"/>
                <div v-text="`検索結果:${$route.query.search}`"/>
            </div>
            <div v-show="leafList.length == 0" class="text-center pa-4">
                <div class="text-10 text-bold" v-text="'検索結果がありませんでした'"/>
            </div>
        </div>
        <!--TrashView-->
        <div class="ml-5 mb-5" v-if="isTrashView">
            <div class="d-flex-row justify-space-between">
                <div class="shadow--text d-flex align-center">
                    <sm-icon class="fas fa-trash"/>ごみ箱
                </div>
                <div v-show="leafList.length > 0 && !isSearchView">
                    <sm-button round flat color="baseshadow" class="pl-4 pr-4" @click="onClearTrashTrigger">
                        <span class="pr-3 pl-3 white--text">ごみ箱を空にする</span>
                    </sm-button>
                </div>
            </div>
            <div v-show="leafList.length == 0 && !isSearchView" class="text-center pa-4">
                <div class="text-10 text-bold" v-text="'ごみ箱には記事がありません'"/>
            </div>
        </div>
        <div class="for-flex" v-if="!leafListLoading">
            <sm-button v-if="$store.getters['categoryList/getSelectedId']!==null" color="primary" fab width="50px" height="50px" class="edit-button" @click="onEditButtonClick">
                <sm-icon class="fas fa-plus" size="20px" color="white"/>
            </sm-button>
            <div class="w-100">
                <div v-for="(item, key) in leafList" :key="key" class="container" @click="leafOnClick(item)">
                    <div class="pr-6 pl-6 pt-3 pb-2">
                        <div class="text-7 shadow--text pb-2" v-time="item.timestamp"></div>
                        <div class="text-12 text-bold">
                            {{item.title}}
                        </div>
                        <div class="d-flex justify-end">
                            <sm-loading :size="1" v-show="item.state=='loading'"/>
                            <sm-icon class="fas fa-xmark" color="error" v-show="item.state=='failed'"/>
                            <sm-button fab flat color="base" class="d-block" @click="onCtxMenuClick($event, item)" v-show="item.state=='done'">
                                <sm-icon class="fas fa-ellipsis-v" color="shadow"/>
                            </sm-button>
                        </div>
                    </div>
                    <sm-divider v-if="key!=leafList.length - 1" color="disabled" class="mr-2 ml-2"/>
                </div>
            </div>
            <leaf-context-menu :type="ctxMenu.type" v-show="ctxMenu.visible" :ctxId="ctxMenu.id" :positionX="ctxMenu.positionX" :positionY="ctxMenu.positionY"
            @edit="onEditTrigger" @moveToTrash="onMoveToTrashTrigger" @restoreFromTrash="onRestoreFromTrashTrigger" @remove="onRemoveTrigger"
            @appendLeaf="onAppendLeafTrigger" @moveup="onMoveLeafTrigger('up')" @movedown="onMoveLeafTrigger('down')"/>
            <ConfirmDialog v-model="confirmDialog.visible" :content="confirmDialog.content" okButton cancelButton cancelButtonOutline @ok="onRemoveExec"/>
        </div>
    </div>
</template>

<script>
import ConfirmDialog from './ConfirmDialog.vue';
import LeafContextMenu from './LeafContextMenu.vue';
export default {
    data() {
        return {
            confirmDialog: {
                visible: false,
                content: '記事を削除します。復元することはできません。'
            },
            ctxMenu: {
                id: '',
                type: '',
                visible: false,
                positionX: 0,
                positionY: 0
            }
        }
    },
    computed: {
        isSearchView: {
            get() {
                return this.$route.query.search !== undefined;
            }
        },
        isTrashView: {
            get() {
                return this.$route.query.trash == 'true';
            }
        },
        leafList: {
            get() {
                return this.$store.getters['leafList/getList'];
            }
        },
        leafListLoading: {
            get() {
                return this.$store.getters['leafList/getListLoadingState'];
            }
        },
        selectedCategoryId: {
            get() {
                return this.$store.getters['categoryList/getSelectedId'];
            }
        }
    },
    components: {
        LeafContextMenu,
        ConfirmDialog
    },
    watch: {
        $route(to, from) {
            this.updateLeafList();
        },
        selectedCategoryId(v) {
            this.updateLeafList();
        }
    },
    methods: {
        // URLのクエリに基づいて記事一覧を表示する
        /*
            /:id: カテゴリID / 指定しない場合は先頭のカテゴリを表示
            ?search=: 文字列検索
            ?trash=: trueの場合はゴミ箱の記事を表示 / 指定しない場合はゴミ箱にない記事を表示
        */
        updateLeafList() {
            let id = this.$route.params.id;
            let q = this.$route.query.search;
            let isInTrash = this.$route.query.trash;
            if (!id) {
                if (this.$store.getters['categoryList/getList'].length > 0 && !this.$store.getters['categoryList/getSelectedId']) {
                    id = this.$store.getters['categoryList/getList'][0].id;
                } else {
                    id = null;
                    if (q || isInTrash) id = this.$store.getters['categoryList/getList'][0].id;
                }
            }
            if (id) {
                this.$store.commit('categoryList/updateSelectedId', id);
                this.$store.dispatch('leafList/fetchList', { force: true });
            }
        },
        leafOnClick(item) {
            this.$router.push(`leaf/${item.id}`);
        },
        onEditButtonClick() {
            this.$router.push('/edit/new');
        },
        onCtxMenuClick(e, item) {
            e.stopPropagation();
            if (this.ctxMenu.visible) {
                this.hideCtxMenu(e);
                return;
            }
            this.ctxMenu.type = this.isTrashView ? 'leafListInTrash' : 'leafList';
            this.ctxMenu.visible = true;
            this.ctxMenu.id = item.id;
            // クリックの外側でクリックしたときにメニューを非表示にする
            document.addEventListener('click', this.hideCtxMenu);
            this.ctxMenu.positionX = e.clientX;
            this.ctxMenu.positionY = e.clientY;
        },
        hideCtxMenu(e) {
            e.stopPropagation();
            this.ctxMenu.visible = false;
            document.removeEventListener('click', this.hidectxMenu);
        },
        onMoveToTrashTrigger() {
            this.$store.dispatch('leafList/moveLeafToTrash', this.ctxMenu.id);
        },
        onRestoreFromTrashTrigger() {
            this.$store.dispatch('leafList/restoreLeafFromTrash', this.ctxMenu.id);
        },
        onRemoveTrigger() {
            this.ctxMenu.visible = false;
            this.confirmDialog.visible = true;
        },
        onRemoveExec() {
            if (this.ctxMenu.id == null) {
                // ごみ箱を空にする
                for (let item of this.leafList) {
                    this.$store.dispatch('leafList/removeLeaf', item.id);
                }
            } else {
                this.$store.dispatch('leafList/removeLeaf', this.ctxMenu.id);
            }
        },
        onClearTrashTrigger() {
            this.ctxMenu.id = null;
            this.confirmDialog.visible = true;
        },
        onEditTrigger() {
            this.$router.push(`/edit/${this.ctxMenu.id}`);
        },
        async onAppendLeafTrigger() {
            let i = this.getItemIndexById(this.ctxMenu.id);
            let before = this.leafList[i].display_index;
            let after = Number(i) + 1 < this.leafList.length ? this.leafList[Number(i) + 1].display_index : before + 200;
            let di = (Number(before) + Number(after)) / 2;
            await this.$store.dispatch('leafList/insertLeaf', { display_index: di });
        },
        onMoveLeafTrigger(direction) {
            let i = this.getItemIndexById(this.ctxMenu.id);
            let di = this.leafList[i].display_index;
            let target;
            if (direction == 'up') {
                if (i == 0) return;
                this.leafList[i].display_index = this.leafList[i - 1].display_index;
                target = this.leafList[i - 1];
            } else if (direction == 'down') {
                if (i == this.leafList.length - 1) return;
                target = this.leafList[i + 1];
            }
            this.leafList[i].display_index = target.display_index;
            target.display_index = di;
            this.$store.dispatch('leafList/updateLeaf', this.leafList[i]);
            this.$store.dispatch('leafList/updateLeaf', target);
        },
        getItemIndexById(id) {
            let i;
            for (i in this.leafList) {
                if (this.leafList[i].id == id) break;
            }
            return Number(i);
        }
    },
    mounted() {
        this.updateLeafList();
    }
}
</script>

<style lang="scss" scoped>
@import './sm/scss/Sm-style-variables.scss';
@import "@/components/scss/column-settings.scss";
.for-flex {
    position: relative;
    display: flex;
    justify-content: flex-end;
    width: 100%;
}
.container {
    &:hover {
        background-color: get-color(focusback);
        cursor: pointer;
    }
}

.edit-button {
    position: fixed;
    margin-right: 40px;
    bottom: 40px;
    cursor: pointer;
}

.leaf-content {
    word-break: break-word;    
}
</style>