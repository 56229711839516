<template>
    <div class="wrapper">
        <leaf-editor v-model:isFormChanged="isFormChanged"/>
    </div>
  </template>
  
<script>
import LeafEditor from '../components/LeafEditor.vue';
export default {
    data() {
        return {
            isFormChanged: false
        }
    },
    components: {
        LeafEditor
    },
    methods: {
        onBeforeunload(e) {
            e.preventDefault();
            e.returnValue = '';
        }
    },
    beforeRouteLeave(to, from, next) {
        if (this.isFormChanged) {
            if (window.confirm('行った変更が保存されない可能性があります。')) {
                next();
            } else {
                next(false);
            }
        } else {
            next();
        }
    },
    created() {
        window.addEventListener('beforeunload', this.onBeforeunload);
    },
    unmounted() {
        window.removeEventListener('beforeunload', this.onBeforeunload);
    }
}
</script>

<style lang="scss" scoped>
@import "@/components/scss/column-settings.scss";
@import "@/components/sm/scss/Sm-style-mediaquery.scss";
.wrapper {
    @include pc {
        margin-top: 20px;
    }
    max-width: $wrapper-width;
    margin-left: auto;
    margin-right: auto;
}
</style>