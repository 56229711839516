import { createStore } from 'vuex'
import $router from '../router'
import fs from '../firebase'

/*********************************************************************
 * Router
 *********************************************************************/
const Router = {
  namespaced: true,
  state: {
    routeName: {},
    routeParams: {},
    routeQuery: {},
    loading: false
  },
  getters: {
    name(state) {
      return state.routeName;
    },
    params(state) {
      return state.routeParams;
    },
    query(state) {
      return state.routeQuery;
    },
    load(state) {
      return state.loading;
    }
  },
  mutations: {
    name(state, payload) {
      state.routeName = payload;
    },
    params(state, payload) {
      state.routeParams = payload;
    },
    query(state, payload) {
      state.routeQuery = payload;
    },
    load(state, payload) {
      state.loading = payload;
    },
  },
  actions: {
    to({state}, { payload, resetQuery} ) {
      if (payload.path == '/' && !resetQuery) {
        payload.query.search = payload.query.search || state.routeQuery.search;
        payload.query.trash = payload.query.trash || state.routeQuery.trash;
      }
      $router.push(payload);
    }
  }
}

/*********************************************************************
 * Auth
 *********************************************************************/
const Auth = {
  namespaced: true,
  state: {
    user: null
  },
  mutations: {
    user(state, payload) {
      state.user = payload;
    }
  },
  getters: {
    user(state) {
      return state.user;
    }
  },
  actions: {
    async signin({state}, arg) {
      // テスト用
      // koyoru12@yahoo.co.jp : leaf@test
      //arg.email = 'koyoru12@yahoo.co.jp'
      //arg.password = 'leaf@test'
      try {
        const userCredential = await fs.signInWithEmailAndPassword(fs.auth, arg.email, arg.password);
        const user = userCredential.user;
        state.user = user;
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    async signout({state}) {
      await fs.signOut(fs.auth);
      state.user = null;
      $store.commit('categoryList/updateList', null);
      $store.commit('categoryList/clearSelectedId');
      $store.commit('leafList/updateList', null);
      $store.commit('leafList/clearCache');
    },
    auth() {
      return new Promise((resolve) => {
        let unsubscribe = fs.auth.onAuthStateChanged((user) => {
          if (user) {
            // user オブジェクトを resolve
            console.log('[auth]' + user.uid);
            $store.commit('auth/user', user);
            resolve(user);
          } else {
            resolve(null);
          }
          // 登録解除
          unsubscribe();
        });
      });
    }
  }
}

/*********************************************************************
 * CategoryList
 *********************************************************************/
const CategoryList = {
  namespaced: true,
  state: {
    collectionName: 'category',
    selectedId: null,
    items: null,
    displayItems: null
  },
  mutations: {
    updateList(state, payload) {
      state.items = payload;
    },
    updateSelectedId(state, payload) {
      if (payload) {
        if (!state.items) return;
        let isIdExists = false;
        for (let i = 0; i < state.items.length; i++) {
          if (state.items[i].id == payload) {
            isIdExists = true;
            break;
          }
        }
        if (!isIdExists) return;
        state.selectedId = payload;
      } else if (state.selectedId == null) {
        if (state.displayItems.length > 0) {
          state.selectedId = state.displayItems[0].id;
        } else {
          state.selectedId = null;
        }
      } else {
        // selectedIdが既に設定されていてpayloadの指定がない場合
        if (payload === undefined) {
          return;           
        } else {
          state.selectedId = null;
        }
      }
    },
    clearSelectedId(state) {
      state.selectedId = null;
    },  
  },
  getters: {
    getList(state) {
      return state.displayItems;
    },
    getSelectedId(state) {
      return state.selectedId;
    }
  },
  actions: {
    async fetchList({ state, dispatch }) {
      if (state.items) return;
      const uid = $store.getters['auth/user'].uid;
      const col = fs.collection(fs.db, state.collectionName);
      let qArray = [];
      qArray.push(fs.where('user_id', '==', uid));
      const query = fs.query(col, ...qArray);
      const docs = await fs.getDocs(query);
      let items = [];
      docs.docs.forEach(doc => {
        let data = doc.data();
        data.id = doc.id;
        data.state = 'done';
        items.push(data);
      });
      $store.commit('categoryList/updateList', items);
      dispatch('updateDisplayItems');
    },
    async insertCategory({state, dispatch}, doc) {
      const col = fs.collection(fs.db, state.collectionName);
      // display_indexが設定されていない場合は設定
      const di = doc.display_index == undefined ? await dispatch('getLastDisplayIndex') + 100 : doc.display_index;
      let localDoc = {
        user_id: $store.getters['auth/user'].uid,
        display_index: di,
        name: doc.name,
        state: 'loading'
      }
      state.items.push(localDoc);
      dispatch('updateDisplayItems');
      try {
        let dbDoc = await fs.addDoc(col, localDoc);
        localDoc.id = dbDoc.id;
        localDoc.state = 'done';
        dispatch('updateDisplayItems');
        return localDoc;
      } catch(e) {
        return null;
      }
    },
    // 更新
    async updateCategory({ state, dispatch }, doc) {
      let localDoc = {
        id: doc.id,
        user_id: doc.user_id,
        display_index: doc.display_index,
        name: doc.name,
        state: 'loading'
      }
      dispatch('updateLocalItem', localDoc);
      dispatch('updateDisplayItems');
      try {
        await fs.setDoc(fs.doc(fs.db, state.collectionName, doc.id), localDoc);
        localDoc.state = 'done';
        dispatch('updateDisplayItems');
        return localDoc;
      } catch(e) {
        console.log(e)
        localDoc.state = 'failed';
        dispatch('updateDisplayItems');
        return null;
      }
    },
    // 削除
    async removeCategory({ state, dispatch }, id) {
      let doc = null;
      for (let i in state.items) {
        if (state.items[i].id == id) {
          doc = state.items[i];          
        }
      }
      if (!doc) return false;
      doc.state = 'loading';
      try {
        await $store.dispatch('leafList/removeLeafByCategory', id);
        await fs.deleteDoc(fs.doc(fs.db, state.collectionName, id));
        for (let i in state.items) {
          if (state.items[i].id == id) {
            state.items.splice(i, 1);
          }
        }
        await dispatch('updateDisplayItems');
        return true;
      } catch(e) {
        console.log(e);
        doc.state = 'failed';
        return false;
      }
    },
    // itemsを更新する
    updateLocalItem({state}, doc) {
      for (let i in state.items) {
        if (state.items[i].id == doc.id) {
          state.items[i] = doc;
        }
      }
    },
    // itemsをdisplayIndex順に並び替え、displayItemsを作成する
    updateDisplayItems({state}) {
      state.displayItems = [];
      state.items.forEach(item => {
        state.displayItems.push(item);
      })
      state.displayItems.sort((a, b) => {
        return a.display_index - b.display_index;
      });
    },
    // 最後尾のDisplayIndexを取得する
    getLastDisplayIndex({state}) {
      let index = 0;
      state.items.forEach(item => {
        index = item.display_index > index ? item.display_index : index;
      });
      return index;
    },
  }
}


/*********************************************************************
 * LeafList
 *********************************************************************/
const LeafList = {
  namespaced: true,
  state: {
    collectionName: 'leaf',
    listLoading: false,
    items: null,
    displayItems: null,
    docCache: {}    // カテゴリごとのキャッシュ
  },
  mutations: {
    updateList(state, payload) {
      state.items = payload;
    },
    clearCache(state) {
      state.docCache = {};
    }
  },
  getters: {
    getAllListInCategory: (state) => {
      return state.items;
    },
    getList: (state) => {
      return state.displayItems;
    },
    getListLoadingState: (state) => {
      return state.listLoading;
    }
  },
  actions: {
    // 一覧取得
    // options: { force: }   > force: 取得を強制する
    async fetchList({ state, commit, dispatch }, options) {
      if (!options) options = {};
      if (!$store.getters['auth/user']) return;
      const uid = $store.getters['auth/user'].uid;
      const categoryId = $store.getters['categoryList/getSelectedId'];
      const col = fs.collection(fs.db, state.collectionName);

      let items = [];
      if (state.items && !options.force) {
        dispatch('updateDisplayItems');
        return;
      }
      if (state.docCache[categoryId]) {
        items = state.docCache[categoryId];
      } else {
        state.listLoading = true;
        let qArray = [];
        qArray.push(fs.where('user_id', '==', uid));
        qArray.push(fs.where('category_id', '==', categoryId));
        const query = fs.query(col, ...qArray);
        const docs = await fs.getDocs(query);
        docs.docs.forEach(doc => {
          let data = doc.data();
          data.id = doc.id;
          data.state = 'done';
          items.push(data);
        });
        state.docCache[categoryId] = items;
      }
      commit('updateList', items);
      dispatch('updateDisplayItems');
      state.listLoading = false;
    },
    // 一件取得 キャッシュにある場合はそこから取得する
    async fetchOne({state}, id) {
      if (!$store.getters['auth/user']) return;
      const uid = $store.getters['auth/user'].uid;
      const col = fs.collection(fs.db, state.collectionName);
      /*
      for (let categoryDocs in state.docCache) {
        for (let doc of state.docCache[categoryDocs]) {
          if (doc.id == id) return doc;
        }
      }
      */
      const docRef = fs.doc(col, id);
      const doc = await fs.getDoc(docRef);
      let data = doc.data();
      if (data) {
        data.id = doc.id;
        data.state = 'done';  
      }
      return data;
    },
    // 新規登録
    async insertLeaf({ state, dispatch }, doc) {
      const col = fs.collection(fs.db, state.collectionName);
      // display_indexが設定されていない場合は設定
      const di = doc.display_index == undefined ? await dispatch('getLastDisplayIndex') + 100 : doc.display_index;
      let localDoc = {
        user_id: $store.getters['auth/user'].uid,
        category_id: doc.category_id || $store.getters['categoryList/getSelectedId'],
        title: doc.title || 'タイトル未設定',
        content: doc.content || '',
        display_index: di,
        timestamp: Date.now(),
        is_in_trash: false,
        state: 'loading'
      }
      dispatch('appendMetadata', localDoc);
      state.items.push(localDoc);
      dispatch('updateDisplayItems');
      try {
        let dbDoc = await fs.addDoc(col, localDoc);
        localDoc.id = dbDoc.id;
        localDoc.state = 'done';
        dispatch('updateImageRefMap', {docId: localDoc.id, type: 'add', images: localDoc.images});
        dispatch('updateDisplayItems');
        return localDoc;
      } catch(e) {
        return null;
      }
    },
    // 更新
    async updateLeaf({ state, dispatch }, doc) {
      let localDoc = {
        id: doc.id,
        user_id: doc.user_id,
        category_id: doc.category_id,
        title: doc.title,
        content: doc.content,
        display_index: doc.display_index,
        timestamp: Date.now(),
        is_in_trash: doc.is_in_trash || false,
        state: 'loading'
      }
      dispatch('appendMetadata', localDoc);
      dispatch('updateLocalItem', localDoc);
      dispatch('updateDisplayItems');
      try {
        await fs.setDoc(fs.doc(fs.db, state.collectionName, doc.id), localDoc);
        localDoc.state = 'done';
        dispatch('updateLocalItem', localDoc);
        dispatch('updateDisplayItems');
        // 更新後の紐づけ画像を保存
        dispatch('updateImageRefMap', {docId: localDoc.id, type: 'add', images: localDoc.images});

        // 紐づけがなくなった画像の検出と削除
        let deletedImages = await $store.dispatch('leafList/detectDeletedImages', { before: doc, after: localDoc });
        //console.log(deletedImages)
        dispatch('updateImageRefMap', {docId: localDoc.id, type: 'remove', images: deletedImages});

        return localDoc;
      } catch(e) {
        console.log(e)
        localDoc.state = 'failed';
        dispatch('updateDisplayItems');
        return null;
      }
    },
    // ごみ箱への移動
    async moveLeafToTrash({ state, dispatch }, id) {
      let doc = null;
      for (let i in state.items) {
        if (state.items[i].id == id) {
          doc = state.items[i];          
        }
      }
      doc.is_in_trash = true;
      await dispatch('updateLeaf', doc);
      dispatch('updateDisplayItems');
      return true;
    },
    // ごみ箱から元に戻す
    async restoreLeafFromTrash({ state, dispatch }, id) {
      let doc = null;
      for (let i in state.items) {
        if (state.items[i].id == id) {
          doc = state.items[i];          
        }
      }
      doc.is_in_trash = false;
      await dispatch('updateLeaf', doc);
      dispatch('updateDisplayItems');
      return true;
    },
    // 削除
    async removeLeaf({ state, dispatch }, id) {
      let doc = null;
      for (let i in state.items) {
        if (state.items[i].id == id) {
          doc = state.items[i];          
        }
      }
      if (!doc) return false;
      doc.state = 'loading';
      try {
        await fs.deleteDoc(fs.doc(fs.db, state.collectionName, id));
        for (let i in state.items) {
          if (state.items[i].id == id) {
            state.items.splice(i, 1);
          }
        }
        dispatch('updateDisplayItems');
        // 紐づけがなくなった画像を削除
        dispatch('updateImageRefMap', {docId: doc.id, type: 'remove', images: doc.images});
        return true;
      } catch(e) {
        console.log(e);
        doc.state = 'failed';
        return false;
      }
    },
    // 特定カテゴリを削除できるか確認する
    async canRemoveCategory({state, dispatch}, categoryId) {
      const uid = $store.getters['auth/user'].uid;
      const col = fs.collection(fs.db, state.collectionName);

      let qArray = [];
      qArray.push(fs.where('user_id', '==', uid));
      qArray.push(fs.where('category_id', '==', categoryId));
      const query = fs.query(col, ...qArray);
      const docs = await fs.getDocs(query);

      let canRemove = true;
      docs.docs.forEach(doc => {
        let data = doc.data();
        if (data.is_in_trash == undefined || data.is_in_trash == false) {
            canRemove = false;
        }          
      });
      return canRemove;
    },
    // 特定カテゴリの記事を全消去する
    async removeLeafByCategory({state, dispatch}, categoryId) {
      let targets = [];
      state.items.forEach(item => {
        if (item.category_id == categoryId) targets.push(item);
      });
      let promises = [];
      targets.forEach(target => {
        promises.push(dispatch('removeLeaf', target.id));
      });
      await Promise.all(promises);
    },
    // insert / updateのときにメタデータを付与する
    appendMetadata({state}, doc) {
      // images
      let uid = $store.getters['auth/user'].uid;
      let re = new RegExp(String.raw`[\("]https:\/\/firebasestorage.googleapis.com\/v0\/b\/leaf-ef4dd.appspot.com\/o\/images%2F${uid}%2F([^\)\?]+).+[\)"]`, 'g')
      let images = [];
      let matched;
      while (matched = re.exec(doc.content)) {
          images.push(matched[1]);
      }
      doc.images = images;
    },
    // update時に更新前/更新後の文書を比較して、削除された画像を洗い出す
    detectDeletedImages({state}, { before, after }) {
      let b = before.images;
      let a = after.images;
      let deleted = [];
      let f;
      for (let bi in b) {
        f = false;
        for (let ai in a) {
          if (a[ai] == b[bi]) {
            f = true;
            break;
          }
        }
        if (!f) deleted.push(b[bi]);
      }
      return deleted;
    },
    // imageRefCountを操作する
    // type: add / remove
    updateImageRefMap({state}, {docId, type, images}) {
      for (let image of images) {
        if (type == 'add') {
          $store.dispatch('imageManager/addImageRefMap', {docId: docId, fileName: image});
        } else if (type == 'remove') {
          $store.dispatch('imageManager/removeImageRefMap', {docId: docId, fileName: image});
        }
      }
    },
    // itemsとdocCacheを更新する
    updateLocalItem({state}, doc) {
      for (let i in state.items) {
        if (state.items[i].id == doc.id) {
          state.items[i] = doc;
        }
      }
    },
    // itemsをdisplayIndex順に並び替え、displayItemsを作成する
    /*
      URLパラメータ
        /:id: カテゴリID / 指定しない場合は先頭のカテゴリを表示
        ?search=: 文字列検索
        ?trash=: trueの場合はゴミ箱の記事を表示 / 指定しない場合はゴミ箱にない記事を表示
    */
    updateDisplayItems({ state }) {
      state.displayItems = [];
      let routeName = $store.getters['router/name'];
      let routeId = $store.getters['router/params'].id;
      let doc = null;
      if (routeName == 'leaf' && routeId) {
        for (let i in state.items) {
          if (state.items[i].id == routeId) {
            doc = state.items[i];          
          }
        }  
      }
      if (routeName == 'leaf' && routeId && !doc) return null;

      state.items.forEach(item => {
        let displayFlg = true;
        // 個別ページの場合   LeafHeaderに表示する記事について、表示中の記事がごみ箱で、LeafHeaderの記事がごみ箱でない場合・その逆の場合は表示しない
        if (routeName == 'leaf') {
          if (doc.is_in_trash && !item.is_in_trash) {
            displayFlg = false;
          } else if (!doc.is_in_trash && item.is_in_trash) {
            displayFlg = false;
          }
        // 一覧ページの場合
        } else {
          // 検索          
          if ($store.getters['router/query'].search && item.content.indexOf($store.getters['router/query'].search) < 0) {
            displayFlg = false;
          }
          // ゴミ箱
          if ($store.getters['router/query'].trash && !item.is_in_trash) {
            displayFlg = false;
          } else if (!$store.getters['router/query'].trash && item.is_in_trash) {
            displayFlg = false;
          }
        }
        if (displayFlg) state.displayItems.push(item);
      })
      state.displayItems.sort((a, b) => {
        return a.display_index - b.display_index;
      });
    },
    // 最後尾のDisplayIndexを取得する
    getLastDisplayIndex({state}) {
      let index = 0;
      state.items.forEach(item => {
        index = item.display_index > index ? item.display_index : index;
      });
      return index;
    },
  }
}

/*********************************************************************
 * ImageManager
 *********************************************************************/
const ImageManager = {
  namespaced: true,
  state: {
    collectionName: 'image_ref_map'
  },
  actions: {
    async fetchImageRefMap({state}, fileName) {
      const col = fs.collection(fs.db, state.collectionName);
      let userId = $store.getters['auth/user'].uid;
      let qArray = [];
      qArray.push(fs.where('user_id', '==', userId));
      qArray.push(fs.where('image', '==', fileName));
      const query = fs.query(col, ...qArray);
      const docs = await fs.getDocs(query);
      let items = [];
      docs.docs.forEach(doc => {
        let data = doc.data();
        data.id = doc.id;
        items.push(data);
      });
      return items;
    },
    async __updateImageRefMap({state, dispatch}, {docId, fileName, type}) {
      let buildDoc = (userId, fileName, refs) => {
        return {
          user_id: userId,
          image: fileName,
          refs: refs
        }
      };
      let items = await dispatch('fetchImageRefMap', fileName);
      if (items.length > 0) {
        let item = items[0];
        if (type == 'add') {
          let isExists = false;
          for (let ref of item.refs) {
            if (ref == docId) {
              isExists = true;
              break;
            }
          }
          if (!isExists) item.refs.push(docId);
        } else if (type == 'remove') {
          for (let i in item.refs) {
            if (item.refs[i] == docId) {
              item.refs.splice(i, 1);
              break;
            }
          }
        }
        if (item.refs.length > 0) {
          await fs.setDoc(fs.doc(fs.db, state.collectionName, item.id), buildDoc(item.user_id, item.image, item.refs));
        } else {
          await fs.deleteDoc(fs.doc(fs.db, state.collectionName, item.id));
          await $store.dispatch('storage/remove', fileName);
        }
      } else if (items.length == 0 && type == 'add') {
        const col = fs.collection(fs.db, state.collectionName);
        let userId = $store.getters['auth/user'].uid;
        let dbDoc = await fs.addDoc(col, buildDoc(userId, fileName, [docId]));
      }
    },
    async addImageRefMap({state, dispatch}, {docId, fileName}) {
      await dispatch('__updateImageRefMap', { docId: docId, fileName: fileName, type: 'add' });
    },
    async removeImageRefMap({state, dispatch}, {docId, fileName}) {
      await dispatch('__updateImageRefMap', { docId: docId, fileName: fileName, type: 'remove' });
    }
  }
}

/*********************************************************************
 * Storage
 *********************************************************************/
const Storage = {
  namespaced: true,
  state: {},
  actions: {
    async upload({ state }, file) {
      let uuid = crypto.randomUUID();
      let uid = $store.getters['auth/user'].uid;
      let file_type = file.name.split('.').pop();
      let path = `images/${uid}/${uuid}.${file_type}`;
      const storageRef = fs.ref(fs.storage, path);
      let snapshot = await fs.uploadBytes(storageRef, file, {
        contentType: 'image/png'
      });
      let url = await fs.getDownloadURL(storageRef);
      return url;
    },
    async remove({state}, fileName) {
      let uid = $store.getters['auth/user'].uid;
      let path = `images/${uid}/${fileName}`;
      const storageRef = fs.ref(fs.storage, path);
      try {
        await fs.deleteObject(storageRef);
        return true;
      } catch(e) {
        console.log(e);
        return false;
      }
    }
  }
}

/*********************************************************************
 * SelectBar
 *********************************************************************/
const SelectBar = {
  namespaced: true,
  state: {
    maxIndex: 0,
    index: 0,   // バーのデフォルト表示位置（0~2）
    index_move: 0   // バーの移動中位置（0~2）小数点あり
  },
  getters: {
    index(state) {
      return state.index;
    },
    index_move(state) {
      return state.index_move;
    },
    maxIndex(state) {
      return state.maxIndex;
    }
  },
  mutations: {
    maxIndex(state, payload) {
      state.maxIndex = payload;
    },
    index(state, payload) {
      state.index = payload;
    },
    index_move(state, payload) {
      state.index_move = payload;
    }
  }
}

/*********************************************************************
 * ZoomImage
 *********************************************************************/
const ZoomImage = {
  namespaced: true,
  state: {
    src: '',
  },
  getters: {
    src(state) {
      return state.src;
    }
  },
  mutations: {
    src(state, payload) {
      state.src = payload;
    }
  }
}

const ThemeController = {
  namespaced: true,
  state: {
    theme: null,
    localStorageKey: 'theme'
  },
  getters: {
    theme(state) {
      if (state.theme !== null) {
        return state.theme;
      } else {
        let theme = localStorage.getItem(state.localStorageKey);
        if (!theme) {
          theme = 'light';
        }
        state.theme = theme;
        return theme;
      }
    }
  },
  actions: {
    switchTheme({state}, theme) {
      if (theme !== 'light' && theme !== 'dark') return;
      state.theme = theme;
      localStorage.setItem(state.localStorageKey, theme)
      if (theme == 'dark') {
        document.body.classList.remove('theme-light');
        document.body.classList.add('theme-dark');
      } else {
        document.body.classList.add('theme-light');
        document.body.classList.remove('theme-dark');
      }
    }
  }
}

const $store = createStore({
  modules: {
    router: Router,
    auth: Auth,
    categoryList: CategoryList,
    leafList: LeafList,
    imageManager: ImageManager,
    storage: Storage,
    selectBar: SelectBar,
    zoomImage: ZoomImage,
    themeController: ThemeController
  }
})

export default $store;