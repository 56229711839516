<template>
    <sm-dialog v-model="dialogModel">
        <sm-card class="pa-5">
            <div class="mb-3 text-bold">
                Note
            </div>
            <div class="mb-5" v-html="content"/>
            <div class="justify-end d-flex">
                <div class="d-flex justify-end">
                    <sm-button v-if="okButton" flat :outline="okButtonOutline" color="error" class="mr-4" @click="onOKClick">OK</sm-button>
                    <sm-button v-if="cancelButton" flat :outline="cancelButtonOutline" color="error" @click="onCancelClick">キャンセル</sm-button>
                </div>
            </div>
        </sm-card>          
    </sm-dialog>
</template>

<script>
import mq from "@/plugins/mq";
export default {
    props: {
        modelValue: Boolean,
        content: String,
        okButton: Boolean,
        okButtonOutline: Boolean,
        cancelButton: Boolean,
        cancelButtonOutline: Boolean
    },
    mixins: [mq],
    computed: {
        dialogModel: {
            get() {
                return this.modelValue;
            },
            set(v) {
                this.$emit('update:modelValue', v);
            }
        }
    },
    methods: {
        onOKClick() {
            this.dialogModel = false;
            this.$emit('ok');
        },
        onCancelClick() {
            this.dialogModel = false;
            this.$emit('cancel');
        }
    }
}
</script>