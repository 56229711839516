<template>
    <div>
        <div v-if="!ctx" class="d-flex justify-center mt-5">
            <sm-loading class="pa-8" :size="6" color="primary"/>
        </div>
        <swiper v-if="ctx" class="for-fixed" @swiper="onSwiper" @slideChange="onSlideChange" :threshold="10" :touchRatio="0.6" :zoom="true">
            <swiper-slide v-for="(item, key) in items" :key="key">
                <div :class="{'ma-3': mq=='sp', 'ma-5':mq=='pc'}" v-if="item.id==ctx.id">
                    <div class="d-flex justify-end pt-4">
                        <div class="text-8 mr-5" v-time="ctx.timestamp"/>
                        <!--    コンテキストメニュー
                        <sm-button flat tile fab color="base" @click="onCtxMenuClick($event)">
                            <sm-icon class="fas fa-ellipsis-v" color="shadow"/>
                        </sm-button>
                        -->
                    </div>
                    <LeafContextMenu type="leafItem" v-show="ctxMenu.visible" :positionX="ctxMenu.positionX" :positionY="ctxMenu.positionY"
                    @edit="$router.push(`/edit/${ctx.id}`)" @remove="onRemoveTrigger"/>
                    <div class="title" v-text="ctx.title"/>
                    <div v-html="ctxHtml" ref="vHtmlElement"/>
                </div>
                <ConfirmDialog v-model="confirmDialog.visible" :content="confirmDialog.content" @ok="onRemoveExec"/>
                <sm-loading-wrapper v-model="loadingRemove"/>
            </swiper-slide>
        </swiper>
        <div class="fixed-right">
            <sm-button color="primary" fab width="50px" height="50px" class="edit-button" @click="onEditButtonClick">
                <sm-icon class="fas fa-pen" size="20px" color="white"/>
            </sm-button>
        </div>
    </div>
</template>

<script>
// threshold: どのくらい引っ張ったらスワイプを検知するか
// touchRatio: どのくらいスワイプしたら遷移するか
import LeafContextMenu from './LeafContextMenu.vue';
import ConfirmDialog from './ConfirmDialog.vue';
import mq from '@/plugins/mq';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
let swiper = null;

export default {
    props: {
        ctx: Object,
        ctxHtml: String
    },
    mixins: [mq],
    data() {
        return {
            loadingDoc: false,
            loadingRemove: false,
            confirmDialog: {
                visible: false,
                content: '記事を削除します。復元することはできません。'
            },
            ctxMenu: {
                visible: false,
                positionX: 0,
                positionY: 0
            }
        };
    },
    watch: {
        ctx() {
            window.scroll(0, 0);
            this.setSlideIndexNeutral();
        },
        ctxHtml() {
            this.setEventTovHtmlElement();
        },
        '$route.params.id'() {
            this.setSlideIndexNeutral();
        }
    },
    computed: {
        items: {
            get() {
                return this.$store.getters['leafList/getList'];
            }
        }
    },
    methods: {
        setSlideIndexNeutral() {
            let i = this.getDefaultIndex();
            if (i == null) return;
            swiper.slideTo(i, 0);
        },
        setEventTovHtmlElement() {
            // ctxHtmlの変更を検知してからv-htmlのDOMが書き換わるまで待つ
            this.$nextTick(() => {
                this.$refs.vHtmlElement[0].querySelectorAll('img').forEach((img) => {
                    img.onclick = () => {
                        this.$store.commit('zoomImage/src', img.src);
                    }
                });
            })
        },
        getDefaultIndex() {
            if (!swiper || !this.ctx) return null;
            let i;
            for (i in this.items) {
                if (this.items[i].id == this.ctx.id) break;
            }
            return i;
        },
        onSwiper(instance) {
            swiper = instance;
            this.setSlideIndexNeutral();
        },
        onSlideChange(swiper) {
            let i = this.getDefaultIndex();
            if (swiper.activeIndex < i) this.$emit('toLeft');
            if (swiper.activeIndex > i) this.$emit('toRight');
        },
        onImageClick() {

        },
        onEditButtonClick() {
            this.$router.push(`/edit/${this.ctx.id}`);
        },
        onCtxMenuClick(e) {
            e.stopPropagation();
            this.ctxMenu.visible = true;
            // クリックの外側でクリックしたときにメニューを非表示にする
            document.addEventListener('click', this.hideCtxMenu);
            this.ctxMenu.positionX = e.clientX;
            this.ctxMenu.positionY = e.clientY - 140;   /* なぜか下にずれるので補正 */
        },
        hideCtxMenu(e) {
            e.stopPropagation();
            this.ctxMenu.visible = false;
            document.removeEventListener('click', this.hidectxMenu);
        },
        onRemoveTrigger() {
            this.ctxMenu.visible = false;
            this.confirmDialog.visible= true;
        },
        async onRemoveExec() {
            this.loadingRemove = true;
            await this.$store.dispatch('leafList/removeLeaf', this.$route.params.id);
            this.$router.push('/')
        },
    },
    components: { LeafContextMenu, ConfirmDialog, Swiper, SwiperSlide },
}
</script>

<style lang="scss" scoped>
@import './scss/column-settings.scss';
.title {
    font-size: 32px;
    font-weight: bold;
    padding-bottom: 20px;
}
.for-fixed {
    display: flex;
    justify-content: flex-end;
}
.fixed-right {
    display: flex;
    justify-content: flex-end;
}
.edit-button {
    position: fixed;
    bottom: 40px;
    margin-right: 40px;
    cursor: pointer;
    z-index: 100;
}
</style>