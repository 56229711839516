<template>
    <div>
        <select v-model="selectModel" name="hoge"
        :class="{
            [`${backgroundcolor}--bg`]: backgroundcolor ? true : false,
            [`${color}--text`]: color ? true: false,
            [`${bordercolor}--border`]: bordercolor==undefined ? false : true,
        }"
        :style="{
            'width': width,
            'height': height,
            'border-color': bordercolor,
            'border-width': `${borderwidth? borderwidth : 1}px`,
        }">
            <option v-if="placeholder" :value="placeholder" hidden v-text="placeholder"/>
            <option class="pa-2" v-for="(item, key) in options" :value="label==''?item:item[label]" v-text="label==''?item:item[label]" :key="key"/>
        </select>
    </div>
</template>

<script>
/*
<sm-select v-model="selectModel" options="optionArray" placeholder="選択してください"/>
*/

export default {
    props: {
        modelValue: String,
        options: Array,         // 選択肢の配列（文字列 or オブジェクト）の配列
        label: {                // optionsがオブジェクトの配列の場合、選択肢に表示するプロパティ名
            type: String,
            default: ''
        },
        placeholder: String,    // placeholder 兼 デフォルトの選択項目
        width: String,
        height: String,
        backgroundcolor: String,
        color: String,
        bordercolor: String,
        borderwidth: Number
    },
    computed: {
        selectModel: {
            get() {
                return this.modelValue;
            },
            set(v) {
                this.$emit('update:modelValue', v);
            }
        }
    },
    mounted() {
        this.selectModel = this.placeholder;
    }
}
</script>