<template>
    <div>
        <MDToolbar :editorView="editorView" :syncScroll="syncScroll" class="mb-3" @insertImage="onToolBarImageClick" @insertTable="onToolBarInsertTabelClick" @insertBlankQuestion="onToolBarInsertBlankQuestion"
        @howToMarkDown="onToolbarHowToMarkdownClick" @editOnlyView="this.editorView='editorOnly'" @editPreviewView="this.editorView='editorAndPreview'" @previewOnlyView="this.editorView='previewOnly'"
        @toggleSyncScroll="this.syncScroll=!this.syncScroll"/>
        <div class="d-flex w-100">
            <MDEditor :placeholder="init.editor.placeholder" v-show="editorView=='editorAndPreview'||this.editorView=='editorOnly'" :modelValue="modelValue" class="editor-layout"
            :bordercolor="bordercolor" :backgroundcolor="backgroundcolor" :borderwidth="borderwidth"
            @mounted="onEditorMounted" @update:modelValue="onInput" @keyup="onKeyup" @save="$emit('save')" ref="editor"/>
            <MDPreview :context="modelValue" :cursorLines="cursorLines" :syncScroll="syncScroll"
            class="preview-layout" :show="mq=='pc'&&(editorView=='editorAndPreview'||this.editorView=='previewOnly')||mq=='sp'&&editorView=='previewOnly'"/>
        </div>
        <TableEditDialog v-model="tableDialog.show" @ok="onDialogInsertTable"/>
        <HowToMarkDownDialog v-model="howToMarkDownDialog.show"/>
    </div>
</template>

<script>
import MDToolbar from './MDToolbar.vue';
import MDEditor from './MDEditor.vue';
import MDPreview from './MDPreview.vue';
import TableEditDialog from './TableEditDialog.vue';
import HowToMarkDownDialog from './HowToMarkDownDialog.vue';
import editorEvents from './editorEvents';
import mq from "@/plugins/mq";

export default {
    props: {
        modelValue: String,
        bordercolor: String,
        backgroundcolor: String,
        borderwidth: Number,
        init: {
            editor: {
                placeholder: String
            }
        }
    },
    data() {
        return {
            editorRef: null,
            editorView: 'editorAndPreview',    //editorAndPreview  editorOnly  previewOnly
            syncScroll: true,
            cursorLines: 0,
            tableDialog: { show: false },
            howToMarkDownDialog: { show: false },
        }
    },
    mixins: [mq],
    watch: {
        mq(oldV, nV) {
            if (nV == 'sp' && this.editorView == 'editorAndPreview') {
                this.editorView = 'editorOnly';
            }
        }
    },
    components: {
        MDEditor,
        MDPreview,
        MDToolbar,
        TableEditDialog,
        HowToMarkDownDialog
    },
    methods: {
        onEditorMounted(editor) {
            this.editorRef = editor;
        },
        onKeyup() {
            this.scrollSync();
        },
        onInput(v) {
            this.$emit('update:modelValue', v);
        },
        async onToolBarImageClick() {
            let res = await editorEvents.insertImage(this.editorRef);
            if (res) {
                this.$emit('update:modelValue', this.editorRef.value);
                this.$emit('save');
            }
        },
        onToolBarInsertTabelClick() {
            this.tableDialog.show = true;
        },
        onToolBarInsertBlankQuestion() {
            editorEvents.attachBlank(this.editorRef);
            this.$emit('update:modelValue', this.editorRef.value);
        },
        onToolbarHowToMarkdownClick() {
            this.howToMarkDownDialog.show = true;
        },
        onDialogInsertTable(data) {
            this.tableDialog.show = false;
            editorEvents.insertTableByDialog(this.editorRef, data);
            this.$emit('update:modelValue', this.editorRef.value);
        },
        scrollSync() {
            let cursorLeft = this.editorRef.value.substring(0, this.editorRef.selectionStart);
            this.cursorLines = cursorLeft.split('\n').length - 1;
        }
    },
    mounted() {
        if (this.mq == 'pc') {
            this.editorView = 'editorAndPreview';
        } else {
            this.editorView = 'editorOnly';
        }
    }
}
</script>

<style lang="scss" scoped>
.editor-layout {
    flex: 1;
}
.preview-layout {
    flex: 1;
}
</style>
