<template>
    <div class="d-flex wrapper">
        <div class="leaf-wrapper">
            <slot/>
        </div>
        <div class="menu-wrapper" v-show="mq=='pc'">
            <div class="p-sticky">
                <slot name="menu-top"/>
                <sm-card class="pt-2 pb-2 menu-list scroll-box">
                    <menu-list/>
                </sm-card>
            </div>
        </div>
    </div>
  </template>
  
<script>
import MenuList from '@/components/SideMenu/MenuList.vue';
import mq from '../plugins/mq';
export default {
    mixins: [mq],
    components: {
        MenuList
    }
}
</script>
  
<style lang="scss" scoped>
@import "@/components/scss/column-settings.scss";
@import "@/components/scss/scrollbar.scss";
@import "@/components/sm/scss/Sm-style-mediaquery.scss";
.wrapper {
    @include pc {
        margin-top: 20px;
    }
    max-width: $wrapper-width;
    margin-left: auto;
    margin-right: auto;
}
.menu-wrapper {
    flex: 1;
    max-width: $sidemenu-column-width;
    margin-right: auto;
}
.menu-list {
    max-height: 40%;
    overflow-y: scroll;
}

.leaf-wrapper {
    flex: 1;
    padding-bottom: 240px;
    margin-left: auto;
    @include pc {
        margin-right: 30px;
        min-width: 500px;
    }
    @include sp {
        min-width: 350px;
    }
}
.p-sticky {
    position: sticky;
    top: calc($app-header-height + 20px);
    height: 100vh;
}

</style>