<template>
  <div :style="setCssVar">
    <button
      :type="type"
      @click.stop.prevent="onClick"
      @hover="onHover"
      :disabled="disabled"
      :class="{
        round: round,
        fab: fab,
        block: block,
        tile: tile,
        flat: flat,
        outline: outline,
        [`${color}--text`]: outline,
        [`${color}--bg`]: true
      }"
      :style="{ width: width, height: height }"
    >
      <slot />
    </button>
  </div>
</template>

<script>
/*
props
  type: htmlのボタンtype submitなど
  disabled: 無効
  round: 丸角
  fab: 丸
  tile: 四角
  flat: 陰影
  outline: 色なし、枠線あり
  height / width
  color: 色

events:
  click
  hover
*/
export default {
  props: {
    type: String,
    disabled: Boolean,
    round: Boolean,
    fab: Boolean,
    block: Boolean,
    tile: Boolean,
    flat: Boolean,
    outline: Boolean,
    height: String,
    width: String,
    color: String
  },
  computed: {
    setCssVar() {
      return {
        "--radius": this.height || "40px",
        "width": this.width,
        "height": this.height
      };
    }
  },
  methods: {
    onClick(e) {
      this.$emit("click", e);
    },
    onHover() {
      this.$emit("hover");
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./scss/Sm-style-variables.scss";

button {
  min-width: 80px;
  min-height: 40px;
  border-radius: 5px;
  box-shadow: 2px 2px 6px -1px get-color(baseshadow);
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    background-color: get-color(disabled);
  }
}
.round {
  border-radius: var(--radius);
}
.block {
  display: block;
  width: 100%;
}
.fab {
  min-width: 20px;
  min-height: 20px;
  border-radius: 50%;
}
.tile {
  border-radius: 0px;
}
.flat {
  box-shadow: none;
}
.outline {
  background-color: get-color(base);
  border: 1px solid;
}
</style>
