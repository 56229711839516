<template>
    <div>
        <div v-if="!ctx" class="d-flex justify-center">
            <sm-loading :size="2" />
        </div>
        <div v-if="ctx">
            <div class="text-bold primary--text pl-3 pt-3 pr-3">
                <sm-icon class="fas fa-flag" /> インデックス
            </div>
            <div class="toc pb-3" v-html="ctx"/>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        ctx: String
    },
    data() {
        return {
            tocText: ''
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../sm/scss/Sm-style-variables.scss';
.toc {
    overflow-y: auto;
}
::v-deep .toc ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    padding-left: 1em;
}
::v-deep .toc a {
    color: get-color(default);
    display: block;
    padding: 5px;
    &:hover {
        background-color: get-color(focusback);
    }
}
</style>