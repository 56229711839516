import $store from '@/store';

const editorEvents = (function() {
    let cursorPosition = null;
    let selectionStart = null;
    let selectionEnd = null;
    let selectedText = null;
    let cursorLeft = null;
    let cursorRight = null;
    let lines = null;
    let currentLine = null;
    let v = null;

    const importSettings = (target) => {
        cursorPosition = target.selectionStart;
        selectionStart = target.selectionStart;
        selectionEnd = target.selectionEnd;
        selectedText = target.value.substring(selectionStart, selectionEnd);
        cursorLeft = target.value.substring(0, selectionStart);
        cursorRight = target.value.substring(selectionEnd, target.value.length);
        lines = cursorLeft.split('\n');
        currentLine = lines[lines.length - 1];
        v = target.value;
    }

    const insertTab = (target) => {
        importSettings(target);
        target.value = cursorLeft + '\t' + selectedText + cursorRight;
        target.selectionEnd = cursorPosition + 1;
    }

    const paste = (target) => {
         //let items =        
    }

    const attachBold = (target) => {
        importSettings(target);
        let re = /^\*\*(.*)\*\*$/;
        let selection = '';
        if (selectedText.match(re)) {
            selection = selectedText.match(re)[1];
            target.value = cursorLeft + selection + cursorRight;
        } else {
            selection = '**' + selectedText + '**';
            target.value = cursorLeft + selection + cursorRight;
        }
        target.selectionStart = selectionStart;
        target.selectionEnd = selectionStart + selection.length;        
    }

    const attachQuote = (target) => {
        importSettings(target);
        let selectedLines = selectedText.split('\n');
        let detach = false;
        selectedLines.forEach(line => {
            if (line.match(/> /)) detach = true;
        });
        for (let i in selectedLines) {
            if (detach) {
                selectedLines[i] = selectedLines[i].replace(/^> /, '');
            } else {
                selectedLines[i] = '> ' + selectedLines[i];
            }
        }    
        selectedText = selectedLines.join('\n');
        target.value = cursorLeft + selectedText + cursorRight;
        target.selectionStart = selectionStart;
        target.selectionEnd = selectionStart + selectedText.length;
    }

    const attachBlank = (target) => {
        importSettings(target);
        let re = /^\[\!(.*)\]$/;
        let selection = '';
        if (selectedText.match(re)) {
            selection = selectedText.match(re)[1];
            target.value = cursorLeft + selection + cursorRight;
        } else {
            selection = '[!' + selectedText + ']';
            target.value = cursorLeft + selection + cursorRight;
        }
        target.selectionStart = selectionStart;
        target.selectionEnd = selectionStart + selection.length;        
    }

    const insertImage = async (target) => {
        importSettings(target);
        let handle = null;
        try {
            [handle] = await window.showOpenFilePicker({
                types: [{
                    description: '画像ファイル',
                    accept: {
                        'image/*': ['.png', '.jepg', '.jpg']
                    }
                }],
                excludeAcceptAllOption: true,
                multiple: false
            });
            let file = await handle.getFile();
            await uploadImage(target, file);
            return true;
        } catch(e) {
            return false;
        }
    }

    const pasteImage = async(target, file) => {
        importSettings(target);
        try {
            await uploadImage(target, file);
            return true;
        } catch(e) {
            return false;
        }
    }

    const uploadImage = async(target, file) => {
        target.value = cursorLeft + `[アップロード中…]` + selectedText + cursorRight;
        target.selectionEnd = cursorPosition;
        let url = await $store.dispatch('storage/upload', file);
        target.value = cursorLeft + `![${file.name}](${url})` + selectedText + cursorRight;
        target.selectionEnd = cursorPosition;
    }

    const insertList = (target) => {
        importSettings(target);
        target.value = cursorLeft + '\n- ' + selectedText + cursorRight;
        target.selectionEnd = cursorPosition + ('\n- ' + selectedText).length;
    }

    const insertTableByLine = (target) => {
        importSettings(target);
        let re = /^([1-9])x([1-9])$/;
        let col = currentLine.match(re)[1];
        let row = Number(currentLine.match(re)[2]) + 1;
        insertTable(target, row, col);
    }

    const insertTableByDialog = (target, settings) => {
        importSettings(target);
        insertTable(target, settings.row, settings.column);
    }

    const insertTable = (target, row, col) => {
        let table = '';
        for (let r = 0; r <= row; r++) {
            for (let c = 0; c < col; c++) {
                if (r == 0) {
                    if (c == 0) {
                        table = table + '|'
                    }
                    table = table + ` ${c+1} |`
                } else if (r == 1) {
                    if (c == 0) {
                        table = table + '|'
                    }
                    table = table + ':-:|'
                } else {
                    if (c == 0) {
                        table = table + '|'
                    }
                    table = table + '   |'
                }
            }
            table = table + '\n';
        }
        cursorLeft = cursorLeft.split('\n');
        cursorLeft.pop();
        cursorLeft = cursorLeft.join('\n');
        target.value = cursorLeft + '\n' + table + selectedText + cursorRight;
        target.selectionEnd = cursorPosition - 3;
    }

    return {
        insertTab,
        attachBold,
        attachQuote,
        attachBlank,
        insertImage,
        pasteImage,
        insertList,
        insertTableByLine,
        insertTableByDialog,
    }
}());

export default editorEvents;

