<template>
    <div @mouseover="isHover=true" @mouseleave="isHover=false">
        <slot/>
        <div v-if="isHover">
            <slot name="hover"/>
        </div>
    </div>
</template>

<script>
/*
hoverの判定対象をsm-hover内に含めてから、hoverで出現させたい要素にv-slot:hoverを指定する
<sm-hover>
    <sm-button/>
    <sm-card v-slot:hover/>
</sm-hover/>
*/


export default {
    data() {
        return {
            isHover: false
        }
    }
}
</script>