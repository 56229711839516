import { marked } from "marked";
import sanitizeHtml from "sanitize-html";
import Prism from "prismjs";

// prismのLanguageモジュールのインポート
const requirePrismLanguage = (lang) => {
    require(`prismjs/components/prism-${lang}`)
};

requirePrismLanguage('bash');
requirePrismLanguage('css');
requirePrismLanguage('scss');
requirePrismLanguage('sass');
requirePrismLanguage('python');
requirePrismLanguage('java');



const renderer = new marked.Renderer();
let __LineMapper;
let __TocMapper;

// インデックス用のname属性を付与する
renderer.heading = (text, level) => {
    return __TocMapper.appendTocTagToRenderer(text, level);
}

/**
 * renderer関数のオーバーライド
 */
const renderer_heading = renderer.heading;
const renderer_paragraph = renderer.paragraph;
const renderer_blockquote = renderer.blockquote;
const renderer_code = renderer.code;

renderer.heading = (text, level) => {
    text = renderer_heading(text, level);
    return __LineMapper.appendLineMapToRenderer('heading', text);
}
renderer.paragraph = (text) => {
    text = renderer_paragraph(text);
    return __LineMapper.appendLineMapToRenderer('paragraph', text);
}
renderer.blockquote = (quote) => {
    let text = renderer_blockquote(quote);
    return __LineMapper.appendLineMapToRenderer('blockquote', text);
}

renderer.code = (code, info, escaped) => {
    if (!escaped) {
        code = escape(code, true);
    }
    let lang = '';
    let fileName = '';
    if (info) {
        lang = info.split(':')[0];
        fileName = info.split(':')[1] || '';
    }
    if (fileName) {
        return `<pre><code class="filename">${fileName}</code><code>${code}</code></pre>`
    } else {
        return `<pre><code>${code}</code></pre>`
    }
}

// codeブロック内のエスケープ処理
// 言語指定がされているなどでprism.js側でエスケープ処理されている場合は必要ないが、言語不明でprism.jsを通せなかった場合はここでエスケープ処理をかける
const escapeTest = /[&<>"']/;
const escapeReplace = new RegExp(escapeTest.source, 'g');
const escapeTestNoEncode = /[<>"']|&(?!(#\d{1,7}|#[Xx][a-fA-F0-9]{1,6}|\w+);)/;
const escapeReplaceNoEncode = new RegExp(escapeTestNoEncode.source, 'g');
const escapeReplacements = {
  '&': '&amp;',
  '<': '&lt;',
  '>': '&gt;',
  '"': '&quot;',
  "'": '&#39;'
}
const getEscapeReplacement = (ch) => escapeReplacements[ch];
function escape(html, encode) {
    if (encode) {
      if (escapeTest.test(html)) {
        return html.replace(escapeReplace, getEscapeReplacement);
      }
    } else {
      if (escapeTestNoEncode.test(html)) {
        return html.replace(escapeReplaceNoEncode, getEscapeReplacement);
      }
    }
    return html;
}
// エスケープ処理ここまで


const walkTokens = (token) => {
    // Linemap処理
    const lineMapTargetTokens = [
        'heading',
    ]
    if (lineMapTargetTokens.includes(token.type)) {
        //console.log(token)
        __LineMapper.appendLineMap(token.type, token.raw);
    }
    // codeのシンタックスハイライト処理
    if (token.type == 'code') {
        if (!token.escaped) {
            let text = token.text;
            let re = new RegExp('(?:^```(?<info>.+)?)|(?:```$)', 'g');
            let result = re.exec(token.raw);
            let info = '';
            let lang = '';
            let fileName = '';
            if (result) {
                if (result.groups && result.groups.info) {
                    info = result.groups.info;
                    lang = info.split(':')[0];
                    fileName = info.split(':')[1] || '';
                }    
            }
            
            if (Prism.languages[lang]) {
                text = Prism.highlight(text, Prism.languages[lang], lang);
                token.text = text;
                token.escaped = true;    
            }
            /*
            console.log('info>' + info)
            console.log(lang)
            console.log(fileName)
            */
        }
    }
}

/**
 * LineMapper
 * 行番号とスクロール位置を紐付ける
 */
class LineMapper {
    __src;
    __srcArray;
    __lineTagMap;
    __lineScrollMap;
    constructor(src) {
        this.__src = src;
        this.__srcArray = src.split('\n');
        this.__lineTagMap = { __srcCounter: { src: src, count: 0 } };
        this.__lineScrollMap = [];
    }
    // タグごとに行位置を記録する
    // { heading: [1, 3, 8], paragraph: [...] }
    appendLineMap(tagName, text) {
        // 指定されたテキストの出現位置を特定して、一行ずつ文字数をカウントし、出現位置を超えたらその行数にテキストがあると判定する
        // blockquoteでは[ > aa ]となってしまう一方、テキストは[aa]と送られてくるので特定できない
        // ほか、同じテキストが複数ある場合に対応できていない
        //let srcIndex = this.__src.indexOf(text);

        let index = this.__lineTagMap.__srcCounter.src.indexOf(text);
        let srcIndex = -1;
        if (index >= 0) {
            this.__lineTagMap.__srcCounter.src = this.__lineTagMap.__srcCounter.src.substring(index + text.length);
            srcIndex = index + this.__lineTagMap.__srcCounter.count;
            this.__lineTagMap.__srcCounter.count += index + text.length;    
        }

        let srcArrayIndex;
        if (srcIndex < 0) {
            srcArrayIndex = -1;
        } else {
            let cnt = 0;
            for (srcArrayIndex in this.__srcArray) {
                // 改行コード分+1している
                cnt = cnt + this.__srcArray[srcArrayIndex].length + 1;
                if (cnt > srcIndex) break;
            }    
        }
        if (!this.__lineTagMap[tagName]) this.__lineTagMap[tagName] = [];
        this.__lineTagMap[tagName].push(srcArrayIndex);
    }
    // rendererにフックしてdata-srclinesで行位置を埋め込む
    appendLineMapToRenderer(tokenType, text) {
        let re = /(<[^ >]+)([\s\S]+)$/gm;
        let matches = re.exec(text)
        if (matches && this.__lineTagMap[tokenType]) {
            let map = this.__lineTagMap[tokenType].shift();
            text = matches[1] + ` data-srclines="${map}" ` + matches[2];
        }
        return text;
    }
    // [[1, 80]]
    createLineScrollMap(rootDOM) {
        const offset = rootDOM.getClientRects()[0].y;
        const tokens = rootDOM.querySelectorAll('[data-srclines');
        this.__lineScrollMap = [];
        for (let token of tokens) {
            let line = token.dataset.srclines;
            let y = token.getClientRects()[0].y - offset;
            this.__lineScrollMap.push([line, y]);
        }
        return this.__lineScrollMap;
    }
}

/**
 * 目次作成クラス
 */
class TocMapper {
    __tocObj;
    __tocHeadCount;

    constructor() {
        this.__tocObj = [];
        this.__tocHeadCount = 0;
    }
    appendTocTagToRenderer(text, level) {
        const slug = encodeURI(('toc-' + this.__tocHeadCount).toLowerCase());
        this.__tocObj.push({
            level: level,
            slug: slug,
            title: text
        });
        this.__tocHeadCount++;
        return `<h${level} id=${slug}>${text}</h${level}>\n`;    
    }
    createToc() {
        let tocStr = '';
        let re = /\[\!([^\]]+)\]/;
        for (let head of this.__tocObj) {
            // 穴埋め問題用のエスケープ処理
            let matched = head.title.match(re);
            if (head.title.match(re)) {
                head.title = matched[1];
            }
            // 先頭の空白埋め
            head.title = head.title.trimStart();
            let tocStrLine = ' '.repeat((head.level - 1) * 2) + '- ' + `[${head.title}](#${head.slug})`;
            sanitizeHtml(tocStrLine);
            tocStr = tocStr + tocStrLine + '\n';
        }
        return tocStr;    
    }
}

const markdown = async(src) => {
    src = src || '';
    __LineMapper = new LineMapper(src);
    __TocMapper = new TocMapper();

    let html = await marked(src);
    let re = /\[(?:<\/.+><.+>)?\!(?<cap>[^\]]+)\]/;
    let uuid;
    let reResult;
    while(html.match(re)) {
        uuid = crypto.randomUUID();
        reResult = re.exec(html);
        // prism.jsで付けられたhtmlタグの除去
        let content = reResult.groups.cap.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '');
        html = html.replace(re, `<input type="checkbox" class="blank-question-checkbox" id="${uuid}"><label class="blank-question" for="${uuid}">${content}</label>`);
    }

    return {
        toHtml() {
            return html;
        },
        toToc() {
            return marked(__TocMapper.createToc());
        },
        toScrollMap(rootDOM) {
            return __LineMapper.createLineScrollMap(rootDOM);
        }
    }
}

marked.use({
    async: true,
    breaks: true,
    renderer: renderer,
    walkTokens
});


export default markdown;
/*

{
    tag: h2
    count: 1
    line: 56
}

<p srcline=56>


*/