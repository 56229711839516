<template>
    <sm-dialog v-model="dialogModel">
        <sm-card class="container pa-4">
            <div class="mb-3 text-bold">
                <sm-icon class="fas fa-pen"/>
                {{ title }}
            </div>
            <div>
                <form>
                    <sm-input v-model="inputModel" class="mb-4" width="100%" height="30px"/>
                    <div class="d-flex-row justify-end">
                        <sm-button flat @click="onOKOnClick" class="mr-4" type="submit">
                            OK
                        </sm-button>
                        <sm-button flat outline color="primary" @click="dialogModel=false">
                            キャンセル
                        </sm-button>
                    </div>
                </form>
            </div>
        </sm-card>
    </sm-dialog>
</template>

<script>
export default {
    props: {
        visible: Boolean,
        type: String,
        categoryName: String
    },
    computed: {
        dialogModel: {
            get() {
                return this.visible;
            },
            set(v) {
                this.$emit('update:visible', v);
            }
        },
        inputModel: {
            get() {
                return this.categoryName;
            },
            set(v) {
                this.$emit('update:categoryName', v);
            }
        },
        title: {
            get() {
                return this.type == 'insert' ? '新しいカテゴリを作成' : '名前を編集';
            }
        }
    },
    methods: {
        onOKOnClick() {
            if (this.type == 'insert') {
                this.$emit('insertOk', this.inputModel);
            } else if (this.type == 'edit') {
                this.$emit('editOk', this.inputModel);
            }
            this.dialogModel = false;
        }
    },
}
</script>

<style lang="scss" scoped>
.container {
    min-width: 300px;
}
</style>