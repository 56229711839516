const md = `
# 見出し
\`\`\`
# 見出し1
## 見出し2
### 見出し3
\`\`\`

> # 見出し1
> ## 見出し2
> ### 見出し3

# 太字
\`\`\`
// ショートハンド： テキストを選択してCtrl+B
**太字**
\`\`\`
**太字**

# 穴埋め問題
\`\`\`
// ショートハンド： テキストを選択してCtrl+D
これは[！穴埋め問題]です
\`\`\`
これは[!穴埋め問題]です


# リスト
\`\`\`
- リスト
- リスト
  - リスト
  - リスト
1. 番号付きリスト
1. 番号付きリスト
\`\`\`
> - リスト
> - リスト
>   - リスト
>   - リスト
> 1. 番号付きリスト
> 1. 番号付きリスト

# テーブル
\`\`\`
// ショートハンド： 列数x行数→Enter / ex.) 2x2
|見出し|見出し|
|  --  |  --  |
|  表  |  表  |
|  表  |  表  |
\`\`\`
> |見出し|見出し|
> |  --  |  --  |
> |  表  |  表  |
> |  表  |  表  |

# リンク
\`\`\`
[Leaf](https://leaf.smallnight.net)
\`\`\`
> [Leaf](https://leaf.smallnight.net)

# 画像
\`\`\`
// ショートハンド： Ctrl+I
![MemoryCard](https://memorycard.smallnight.net/img/signin_logo.4f187c9f.png)
\`\`\`

# 引用
\`\`\`
// ショートハンド： テキストを選択してCtrl+Q
> 引用
\`\`\`
> 引用

# その他のショートハンド
|処理|コマンド|
|--|--|
|保存|Ctrl+S|
|元に戻す|Ctrl+Z|
|やり直し|Ctrl+Y|
`

export default md;