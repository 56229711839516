import { createRouter, createWebHistory } from 'vue-router'
import $store from '../store'
import HomeView from '../views/HomeView.vue'
import SigninView from '../views/SigninView.vue'
import LeafView from '../views/LeafView.vue'
import LeafEditorView from '../views/LeafEditorView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: { requireAuth: true },
    children: [
      {
        path: '/',
        component: HomeView,
      },
      {
        path: '/:id',
        component: HomeView,
      }      
    ]
  },
  {
    path: '/signin',
    name: 'signin',
    component: SigninView
  },
  {
    path: '/leaf/:id',
    name: 'leaf',
    component: LeafView,
    meta: { requireAuth: true }
  },
  {
    path: '/edit/:id',
    name: 'editor',
    component: LeafEditorView,
    meta: { requireAuth: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(async (to, from, next) => {
  $store.commit('router/load', true);
  $store.commit('router/name', to.name);
  $store.commit('router/params', to.params);
  $store.commit('router/query', to.query);
  if (to.matched.some(record => record.meta.requireAuth)) {
    if (!await $store.dispatch('auth/auth')) {
      // signin
      $store.commit('router/load', false);
      next('/signin');
    } else {
      await fetchAppData();
      $store.commit('router/load', false);
      next();
    }
  } else {
    $store.commit('router/load', false);
    next();
  }
});

/* componentのcreatedでデータをfetchすると順番通りの処理にならないので
* 事前に必要なデータをダウンロードしてからルーティングするようにしている
*/
const fetchAppData =  async () => {
  await $store.dispatch('categoryList/fetchList');
  await $store.commit('categoryList/updateSelectedId');
  await $store.dispatch('leafList/fetchList');
}

export default router
