<template>
    <div>
        <textarea class="editor pa-3" @input="onInput($event)" @keydown="onKeyDown($event)" @keyup="onKeyup($event)" @paste="onPaste($event)"
        ref="editor"
        :value="modelValue"
        :placeholder="placeholder"
        :class="{
        [`${backgroundcolor}--bg`]: backgroundcolor==undefined ? false : true,
        [`${bordercolor}--border`]: bordercolor==undefined ? false : true,
        'default--text': true,
        }"
        :style="{
        borderWidth: `${borderwidth? borderwidth : 1}px`}"
        />
    </div>
</template>

<script>
import editorEvents from './editorEvents';
export default {
    props: {
        placeholder: String,
        modelValue: String,
        bordercolor: String,
        backgroundcolor: String,
        borderwidth: Number
    },
    data() {
        return {
            undoFlag: false,
            undoStack: [],
            redoStack: []
        }
    },
    methods: {
        onInput(e) {
            if (e.target.value !== this.modelValue && !this.undoFlag) {
                this.redoStack.splice(0);
                this.addUndoHistory(this.modelValue, e.target.selectionStart, e.target.selectionEnd);
            }
            this.$emit('update:modelValue', e.target.value);
        },
        addUndoHistory(text, selectionStart, selectionEnd) {
            this.undoStack.push({ text, selectionStart, selectionEnd });
            if (this.undoStack.length > 20) {
                this.undoStack.shift();
            }
        },
        addRedoHistory(text, selectionStart, selectionEnd) {
            this.redoStack.push({ text, selectionStart, selectionEnd });
            if (this.redoStack.length > 20) {
                this.redoStack.shift();
            }
        },
        undo(e) {
            if (this.undoStack.length > 0) {
                let selectionStart = e.target.selectionStart;
                let selectionEnd = e.target.selectionEnd;
                let undo = this.undoStack.pop();
                this.addRedoHistory(e.target.value, selectionStart, selectionEnd);
                e.target.value = undo.text;
                e.target.selectionStart = undo.selectionStart;
                e.target.selectionEnd = undo.selectionEnd;
                this.undoFlag = true;
                this.onInput(e);
                this.undoFlag = false;
            }
        },
        redo(e) {
            if (this.redoStack.length > 0) {
                let selectionStart = e.target.selectionStart;
                let selectionEnd = e.target.selectionEnd;
                let redo = this.redoStack.pop();
                this.addUndoHistory(e.target.value, selectionStart, selectionEnd);
                e.target.value = redo.text;
                e.target.selectionStart = redo.selectionStart;
                e.target.selectionEnd = redo.selectionEnd;
                this.undoFlag = true;
                this.onInput(e);
                this.undoFlag = false;
            }
        },
        onKeyup(e) {
            this.$emit('keyup', e);
        },
        async onKeyDown(e) {
            await this.keyTrigger(e);
        },
        async onPaste(e) {
            let item = e.clipboardData.items[0];
            if (item.type.indexOf('image') !== -1) {
                // 画像をアップロードする
                let file = item.getAsFile();
                let res = await editorEvents.pasteImage(e.target, file);
                if (res) {
                    this.onInput(e);
                    this.$emit('save');
                }
            }
        },
        async keyTrigger(e) {
            if (e.key == 'Tab') {
                e.preventDefault();
                editorEvents.insertTab(e.target);
                this.onInput(e);
            } else if (e.ctrlKey && e.key=='b') {
                e.preventDefault();
                editorEvents.attachBold(e.target);
                this.onInput(e);
            } else if (e.ctrlKey && e.key=='q') {
                e.preventDefault();
                editorEvents.attachQuote(e.target);
                this.onInput(e);
            } else if (e.ctrlKey && e.key=='d') {
                e.preventDefault();
                editorEvents.attachBlank(e.target);
                this.onInput(e);
            } else if (e.ctrlKey && e.key=='s') {
                e.preventDefault();
                this.$emit('save');
            } else if (e.ctrlKey && e.key=='z') {
                e.preventDefault();
                this.undo(e);
            } else if (e.ctrlKey && e.key=='y') {
                e.preventDefault();
                this.redo(e);
            } else if (e.ctrlKey && e.key=='i') {
                let res = await editorEvents.insertImage(e.target);
                if (res) {
                    this.onInput(e);
                    this.$emit('save');
                }
            } else if (e.key == 'Enter') {
                let selectionStart = e.target.selectionStart;
                let cursorLeft = e.target.value.substring(0, selectionStart);
                let lines = cursorLeft.split('\n');
                let currentLine = lines[lines.length - 1];
                if (currentLine.match(/^[-] .+$/)) {
                    // リストの入力補完
                    e.preventDefault();
                    editorEvents.insertList(e.target);
                } else if (currentLine.match(/^[1-9]x[1-9]$/)) {
                    // 表の入力補完
                    e.preventDefault();
                    editorEvents.insertTableByLine(e.target);
                }
                this.onInput(e);
            }
        },
    },
    mounted() {
        this.$emit('mounted', this.$refs.editor);
    }
}

</script>

<style lang="scss" scoped>
.editor {
    width: 100%;
    height: 520px;
    font-size: 16px;
    box-sizing: border-box;
    font-family: Consolas, "Liberation Mono", Menlo, Courier, monospace;
    font-size: 'inherit';
    font-weight: 'inherit';
    color: 'inherit';
    border-collapse: collapse;
    border-style: solid;
    border-radius: 8px 0px 0px 8px;
}
</style>